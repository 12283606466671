import React, { useEffect, useState,useCallback} from 'react';
import Select from 'react-select';
import Cookies from 'js-cookie';
import { customStyles1,handleApiError,handleInputNameValidation,validateAndSetMobileChange, validateEmail } from '../api/utils/utils';
import PhoneInputWithCountrySelector from '../components/phoneinputcountryselector';
import expand from '../images/expand icon-02.svg';
import Button from '../components/savebutton';
import { useCountryData1 } from '../api/hooks/mobilecountryhook';
import { useCountryOptions } from '../api/hooks/redux-hooks/useReduxActions';
import { privacypolicydomain } from '../api/helper/urlhelper';
import PasswordToggle from '../components/passwordtoggle';
import { sid, Signup, SignupUpdate } from '../api/helper/apiurlhelper';
import Signupverify from './signupverify';
function Signupnew({showErrorToast,setIstrue,setIsLoading4}) {
    const [formValues,setFormValues] = useState({name:'',countrylistoption:[],country:null,countryloading:false,email:'',password:'',confirmpassword:'',ischecked:'',otp:'',countdown:120,uservalues:[]});
    const [showscreen,setShowscreen] = useState({shownewpassword:false,showcurrentpassword:false,isFocused:false,enabled:false,show:false,verifyenabled:false,signupscreen:true,verrifyscreen:false});
    const [inlineerror,setInlineError] = useState({nameerror:'',passworderror:'',mobileerror:'',confirmpassworderror:''});
    const [isInvalid, setIsInvalid] = useState({letter: true,capital: true,special: true,number: true,length: true});
    const [selectedCountry, setSelectedCountry] = useState({country_code: "",dial_code: "",mcc_id: "",mobile_length: "",mobile_starting_digits: "",mobileChange:"",numberinvalid:false});
    const {countryOptions1,isLoading1,loadip} = useCountryData1();
    const [preloadData, setPreloadData] = useState(null);
    const [error422,setError422] = useState('');
    const { countrylistoptions,loadcountry,loading } = useCountryOptions({showErrorToast});
     const userAccounts = useCallback(async() => {
         sid()
          .then((res) => setFormValues((prev)=>({...prev,uservalues:res.data.signup})))
          .catch((error) => handleApiError(error, null, null, true, showErrorToast));
    }, []);
    useEffect(()=>{
        Cookies.remove("otpHashemail");
        Cookies.remove("otpHashmobile");
        loadcountry();
        loadip(true, (data) => {
            if(data){
            setSelectedCountry((prev)=>({
              ...prev,
              country_code: data?.country_code,
              dial_code: data?.dial_code,
              mcc_id: data?.mcc_id,
              mobile_length: data?.mobile_length,
              mobile_starting_digits: data?.mobile_starting_digits,
            }));
          }
          });
    },[]);
    const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
     setFormValues((prevValues) => ({
        ...prevValues,
        [name]: type === 'checkbox' ? checked : value,
     }));
     if(name === 'name'){
        const value = e.target.value;
        setFormValues((prev)=>({...prev,name:value.trim()}))
     }
     if(name === 'email'){
        setInlineError((prev)=>({
            ...prev,emailerror:''
        }))
     }
     if(name === 'password'){
        const newPasswords = e.target.value;
        setFormValues((prev)=>({
        ...prev,password:newPasswords
        }));
        const lowercaseLetters = /[a-z]/g;
        const uppercaseLetters = /[A-Z]/g;
        const specialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|=]/g;
        const numbers = /[0-9]/g;
        setIsInvalid({
            letter: !newPasswords.match(lowercaseLetters),
            capital: !newPasswords.match(uppercaseLetters),
            special: !newPasswords.match(specialCharacters),
            number: !newPasswords.match(numbers),
            length: newPasswords.length < 8,
        });
        setInlineError((prev)=>({
        ...prev,passworderror:''
        }))
     }
     if(name === 'confirmpassword'){
        setInlineError((prev)=>({
            ...prev,confirmpassworderror:''
            }))
     }
     setError422('');
    }  
    const handleCountrylistChange = (selectedOption) => {
    setFormValues((prevValues) => ({
        ...prevValues,
        country: selectedOption,
    }));
    };
    const handleFocus = () => {
        setShowscreen((prevValues) => ({
            ...prevValues,
            isFocused: true,
        }));
    }
    const handleBlur = () => {
        setShowscreen((prevValues) => ({
            ...prevValues,
            isFocused: false,
        }));
    }
    const getPasswordValidationClass = (isValid) => {
        return isValid ? 'valid' : 'invalid';
    };
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(privacypolicydomain(), '_blank', 'noopener,noreferrer');
    }
    const handlePhoneNumberChange = (value) => {
        validateAndSetMobileChange(value, {
          setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
          setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
          setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
          mobile_length: selectedCountry.mobile_length,             
          mobile_starting_digits: selectedCountry.mobile_starting_digits 
        });
      };
      const handlePaste1 = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
        // const dialCodeWithoutPlus = selectedCountry.dial_code?.replace('+', '');
        const dialCodeWithoutPlus = typeof selectedCountry.dial_code === 'string' ? selectedCountry.dial_code.replace('+', '') : '';
        if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
            numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
        }
        validateAndSetMobileChange(numericPastedText, {
          setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
          setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
          setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
          mobile_length: selectedCountry.mobile_length,             
          mobile_starting_digits: selectedCountry.mobile_starting_digits 
        });
        e.preventDefault();
      };
      const handleCountryChange = (selectedOption) => {
        const selectedCountryData = countryOptions1.find(
            (country) => country.value === selectedOption.value
        );
        if (selectedCountryData) {
          const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
          setSelectedCountry((prev)=>({
            ...prev,
            country_code:country_code,
            dial_code: dial_code,
            mcc_id: mcc_id,
            mobile_length: mobile_length,
            mobile_starting_digits: mobile_starting_digits,
          }));
      
          validateAndSetMobileChange(selectedCountry.mobileChange, {
            setMobileChange:(val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
            setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
            setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
            mobile_length : mobile_length,
            mobile_starting_digits : mobile_starting_digits,
          });
        }
    };
    const handleBeforeInputss = (e) => {
        handleInputNameValidation(e,{setError: (errorMsg) => setInlineError((prev) => ({ ...prev, nameerror: errorMsg }))});
    }
    const checksign = useCallback(()=>{
      const valid = (
        formValues.name !== '' && formValues.email !== '' && selectedCountry.mobileChange !== '' && formValues.country !== '' && formValues.country !== null && formValues.password !== '' && formValues.confirmpassword !== '' && selectedCountry.numberinvalid === true && formValues.ischecked === true
      )
      setShowscreen((prev)=>({
        ...prev,enabled:valid
      }));
    },[ formValues.name,formValues.email,selectedCountry.mobileChange,formValues.country,formValues.password,formValues.confirmpassword,selectedCountry.numberinvalid,formValues.ischecked]);
    useEffect(()=>{
        checksign();
    },[checksign])
 
    const signup = async(e)=>{
        e.preventDefault();
        const isEmailValid = validateEmail(formValues.email);
        setInlineError((prevState) => ({
          ...prevState,
          emailerror: isEmailValid ? '' : 'Please enter a valid email address.',
        }));
        if (!isEmailValid) return;
        const allFalse = Object.values(isInvalid).every(value => value === false); 
        if(!allFalse){
          setInlineError((prevState) => ({
            ...prevState,
            passworderror: 'Password invalid',
          }));
          return false;
        }else{
          setInlineError((prevState) => ({
            ...prevState,
            passworderror: '',
          }));
        }
        if (formValues.password !== formValues.confirmpassword) {
            setInlineError((prevState) => ({
              ...prevState,
              confirmpassworderror: 'Passwords do not match',
            }));
            return false;
           }else{
            setInlineError((prevState) => ({
              ...prevState,
              confirmpassworderror: '',
            }));
           }
           const data = {
            name: formValues.name,
            email: formValues.email,
            mobile:selectedCountry.mobileChange,
            mcc_id: selectedCountry.mcc_id,
            country_id: formValues.country.value,
            password: formValues.password,
            confirmpassword:formValues.confirmpassword,
           }
          try{
            setIsLoading4(true);
            const response =  preloadData === null ? await Signup(data) : await SignupUpdate(data);
            if(response.status === 200){
             await userAccounts(); 
             setIstrue(true); 
             setPreloadData(data);
             setShowscreen((prev)=>({...prev,signupscreen:false,verrifyscreen:true}));
             setError422('');
            }
          }catch(error){
             handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
          }finally{ 
            setIsLoading4(false); 
          }
    }
  return (
    <>
    {showscreen.signupscreen && (
    <form autoComplete='off'>
    <input type='text' name='name' placeholder='Name' value={formValues.name} onChange={handleInputChange}  onBeforeInput={handleBeforeInputss} className={`form-control logininputsign mt-3 ${formValues.name ? 'logininputsign-active' : ''}`}></input>
    {inlineerror.nameerror && <div className="errors">{inlineerror.nameerror}</div>}
    <Select  options={countrylistoptions}   value={formValues.country} isLoading={loading}  isSearchable={true} placeholder="Select Country" onChange={handleCountrylistChange} styles={customStyles1(Boolean(formValues.country))} />
    <div className={`signupdivsplit mt-2 ${inlineerror.emailerror || inlineerror.mobileerror ? 'align-items-center' : ''}`}>
     <div className={`width`}>   
     <input type='email' autoComplete='off' name='email' placeholder='Email' value={formValues.email} onChange={handleInputChange} className={`form-control logininputsign ${formValues.email ? 'logininputsign-active' : ''}`}></input>  
     {inlineerror.emailerror && <div className="errors">{inlineerror.emailerror}</div>}
     </div> 
     <div className={`position-relative width`}>
     <PhoneInputWithCountrySelector countryOptions={countryOptions1}  handleCountryChange={handleCountryChange} dialCode={selectedCountry.dial_code} mobileChange={selectedCountry.mobileChange} handlePhoneNumberChange={(e)=>handlePhoneNumberChange(e.target.value)}  handlePaste={handlePaste1} countryCode={selectedCountry.country_code} walletprofileinput = 'logininputsign' walletprofileinputactive='logininputsign-active' isLoading={isLoading1} placeholder="Mobile Number" dialCodeshow={false}/> 
     {inlineerror.mobileerror && <div className="errors">{inlineerror.mobileerror}</div>}
     </div>
    </div>
    <div className={`signupdivsplit mt-2 ${ inlineerror.passworderror || inlineerror.confirmpassworderror ? 'align-items-start':''}`}>
      <div className={`newinput flex-column position-relative ${formValues.password ? 'newinput_active' : ''}`}>
      <div className='d-flex align-items-center'>
        <input type={showscreen.shownewpassword ? 'text' : 'password'} autoComplete='new-password' name='password' placeholder='Password' value={formValues.password} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} className={`form-control logininputsigninside ${formValues.password ? 'logininputsigninside-active' : ''}`} ></input>
              {showscreen.isFocused && (
                  <div id="message4" className={`password-message ${showscreen.isFocused ? "initial-focus" : ""}`}>
                      <h6>Password must contain the following:</h6>
                      <p className={getPasswordValidationClass(isInvalid.letter)}>
                          At least a Lowercase
                      </p>
                      <p className={getPasswordValidationClass(isInvalid.capital)}>
                          At least a Capital (Uppercase)
                      </p>
                      <p className={getPasswordValidationClass(isInvalid.special)}>
                          At least Special Character
                      </p>
                      <p className={getPasswordValidationClass(isInvalid.number)}>A number</p>
                      <p className={getPasswordValidationClass(isInvalid.length)}>
                          Minimum 8 Characters
                      </p>
                  </div>
                  )}
                  <PasswordToggle password={formValues.password}  onToggle={(visible) => setShowscreen((prev) => ({ ...prev, shownewpassword: visible }))}/>
                  </div>  
                 {inlineerror.passworderror && <div className="errors">{inlineerror.passworderror}</div>}
       </div>  
      <div className={`newinput flex-column ${formValues.confirmpassword ? 'newinput_active' : ''}`}>
        <div className='d-flex align-items-center'>
        <input type={showscreen.showcurrentpassword ? 'text' : 'password'} autoComplete='new-password' name='confirmpassword' placeholder='Confirm Password' value={formValues.confirmpassword} onChange={handleInputChange} className={`form-control logininputsigninside ${formValues.confirmpassword ? 'logininputsigninside-active' : ''}`} ></input>
        <PasswordToggle password={formValues.confirmpassword}  onToggle={(visible) => setShowscreen((prev) => ({ ...prev, showcurrentpassword: visible }))}/>   
        </div>
      {inlineerror.confirmpassworderror && <div className="errors">{inlineerror.confirmpassworderror}</div>}     
      </div>  
    </div>
    <input type='text' name='refferal' className='form-control logininputsign mt-2' placeholder='Referral Code'></input>
    <div className="mt-2 d-flex align-items-center gap-2 w-100 max-width">
    <input type="checkbox" name='ischecked' checked={formValues.ischecked} onChange={handleInputChange}></input>
    <p className="singupterms">I agree to the <strong onClick={handleClick} className='text-primary text-decoration-underline cursor-pointer'>Privacy & Terms</strong> <img src={expand} style={{cursor:'pointer'}} onClick={handleClick} alt='expand' width='15px'></img></p>
    </div>
    {error422 && <div className="errors">{error422}</div>}
    <Button defaultclassname='savedefault mt-2' onClick={signup} enabled={showscreen.enabled} activeclassname='savedefault_active' disabledclassname='savedefault_disabled' label='Sign up'/>
    </form>
    )}
    {showscreen.verrifyscreen && (
     <>
      <Signupverify formValues={formValues} setShowscreen={setShowscreen} setFormValues={setFormValues} setIsLoading4={setIsLoading4} selectedCountry={selectedCountry} setIstrue={setIstrue} showErrorToast={showErrorToast}/>
     </> 
    )}

    </>
  )
}

export default Signupnew