import React,{useState,useEffect,useRef,forwardRef} from 'react';
import Select,{components} from 'react-select';
import useMenuHeight, {countrystyle1, handleBeforeInput, selectWidth } from '../api/utils/utils';
const PhoneInputWithCountrySelector = forwardRef(({countryOptions = [],handleCountryChange,dialCode,mobileChange,handlePhoneNumberChange, handlePaste,isLoading = false,minheight,walletprofileinput,walletprofileinputactive,countryCode,id='mobilenumber',newclassname='',placeholder='Enter your Mobile number',dialCodeshow=true,name='responsephoneNumber',numeric=false},ref) => {
  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={data.flag}
        alt=""
        style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
      {!dialCodeshow && (
      <p className="customsinglep">{`+${data.dial_code}`}</p>
     )}
    </div>
  );

  const customOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={props.data.flag}
          alt=""
          style={{ width: '25px', height: 'auto', marginRight: '10px' }}
        />
        {props.data.label}
      </div>
    </components.Option>
  );
  const inputRef = useRef(null);

  useEffect(() => {
    if (ref && inputRef.current) {
        ref.current = inputRef.current;
    }
}, [ref]);

  const [selwidth, setSelWidth] = useState(selectWidth(dialCode));
  const menuHeight = useMenuHeight(200, 400);
  const [inputHeight, setInputHeight] = useState(''); 
  useEffect(() => {
    const input = document.querySelector(`input[name=${name}]`);
    const updateHeight = () => {
      if (input) {
        const computedStyle = window.getComputedStyle(input);
        setInputHeight(parseInt(computedStyle.height, 10));
      }
    };
    updateHeight();
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    if (input) {
      resizeObserver.observe(input);
    }
    return () => {
      if (input) resizeObserver.unobserve(input);
    };
  }, [mobileChange, walletprofileinput]);

  useEffect(() => {
    setSelWidth(selectWidth(dialCode));
  }, [dialCode]);
  const handleKeyPress = (e) => {
    handleBeforeInput(e);
  };
  const load = isLoading ? !dialCodeshow ? '45px' : '82px' : !dialCodeshow ? selwidth - 20 : '82px' 
  return (
      <>
      <div style={{ position: 'absolute', left: 0, zIndex: 0}}>
        <Select options={countryOptions} onChange={handleCountryChange} value={countryOptions.find((option) => option.value === countryCode)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={isLoading}
          styles={countrystyle1(load,menuHeight,inputHeight,dialCodeshow)}
        />
      </div>
      <div
        style={{
          height: '30px',
          width: '2px',
          backgroundColor: '#ccc',
          margin: `${dialCodeshow ? '0 8px' : '0 8px 0px 0px' }`,
          position: 'absolute',
          left: dialCodeshow ? `${selwidth}px` : `${selwidth - 20}px`,
          top: `calc(${inputHeight / 2}px)`,
          transform: 'translateY(-50%)',
        }}
      ></div>
      {dialCodeshow && (
      <p style={{position: 'absolute',left: '80px',top:`calc(${inputHeight / 2}px)`,transform: 'translateY(-50%)',cursor: 'pointer',color: 'hsl(0, 0%, 20%)',fontSize: 'clamp(12px, 1.5vw, 14px)',lineHeight: '1.5', marginBottom: '0px',fontWeight: '400',}}> {dialCode}</p>
      )}
      <input type="text" id={id} name={name} readOnly={isLoading} onPaste={handlePaste} value={mobileChange} onBeforeInput={numeric ? undefined : handleKeyPress} onChange={(e) => handlePhoneNumberChange(e || e.target.value)} className={`form-control  ${newclassname} ${walletprofileinput} ${ mobileChange ? walletprofileinputactive : '' }`} style={{ paddingLeft: dialCodeshow ? `${selwidth + 18}px` : `${selwidth -10}px` }} placeholder={placeholder} ref={ref}/>
      </>
  );
});

export default PhoneInputWithCountrySelector;
