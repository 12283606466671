import React, { useState,useRef,useEffect,useCallback, memo } from 'react';
import WithToast from '../../api/hooks/withtoasthook';
import resetpasswordlock from '../../images/Untitled-1-02.png';
import Select, { components } from 'react-select';
import { useIpContext } from '../../api/hooks/iphook';
import { handleApiError } from '../../api/utils/utils';
import axiosInstance from '../../api/utils/axiosutils';
import { country_codeapi, forgotpasswordsendotpapi } from '../../api/helper/urlhelper';
import Forgototppage from './forgototppage';
import Forgotpasswordchange from './forgotpasswordchange';
import { useNavigate } from 'react-router-dom';
const mcclistapiurl = country_codeapi();
const forgotpasswordsendotpapiurl = forgotpasswordsendotpapi();
const forgotpreferoption = [
    { value: 'Email', label: 'Email' },
    { value: 'Mobile', label: 'Mobile' },
  ];
const Forgotpage = memo(({setIsLoading4,showErrorToast})=>{
  const navigate = useNavigate();
    const [formValues,setFormValues] = useState({
        forgotvalueselect:'Email',forgotemail:'',forgotmobile:'',forgotmccid:'',countryOptions:[],isLoading:false,country_code:'',dial_code:'',
    });
    const [showforgotpage,setShowforgotpage] = useState(true);
    const [formValuesError,setFormValuesError] = useState({
        usernameerror:''
    });
    const [showforgotpageotp,setShowforgotpageotp] = useState(false);
    const [showforgotpagepassword,setShowforgotpagepassword] = useState(false);
    const [error422,setError422] = useState('');
    const [isenabled,setEnabled] = useState(false);
    const [selwidth, setSelwidth] = useState(0);
    const mobileInputRef = useRef(null);
    const customStyles = {
        control: (provided) => ({
          ...provided,
          width:'100%',
          height: '40px',
          border: 'none',
          boxShadow: 'none',
          background: '#EDEDED',
          marginTop:'.5rem',
          borderRadius : '4px',
          }),
          dropdownIndicator: (provided) => ({
          ...provided,
          color:'black',
          padding: '0 8px',
          }),
          indicatorSeparator: () => ({
          display: 'none',
          }),
          singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          fontSize:'14px',
          lineHeigt:'20px',
          fontWeight:500,
          color:'#000000'
          }),
          valueContainer: (provided) => ({
          ...provided,
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '15px',
          
          }),
          input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          menu: (provided) => ({
          ...provided,
          minWidth: '300px',})  
    }
    const customStyles1 = {
        control: (provided) => ({
          ...provided,
          width:'80px',
          height: '40px',
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
          display: 'flex',
          alignItems: 'center',
          }),
          dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0 8px',
          }),
          indicatorSeparator: () => ({
          display: 'none',
          }),
          singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          }),
          valueContainer: (provided) => ({
          ...provided,
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          }),
          input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          menu: (provided) => ({
          ...provided,
          minWidth: '300px',})  
    }
    const { ipCountryCode, getUserIpCountryCode } = useIpContext();
    const updateExampleNumber = useCallback((iso2) => {
        if (window.intlTelInputUtils) {
          try {
            const exampleNumber = window.intlTelInputUtils.getExampleNumber(
              iso2,
              true,
              window.intlTelInputUtils.numberFormat.E164
            );
            const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
            const maxLength = cleanedNumber.length;
            return maxLength;
          } catch (error) {
            console.error('Error fetching example number for country code:', iso2, error);
            return 15; // Fallback to a default length
          }
        } else {
          console.error('intlTelInputUtils is not loaded');
          return 15; // Fallback to a default length
        }
      }, []);
    const fetchCountryCode = useCallback(async () => {
        if (ipCountryCode){ 
        if (formValues.countryOptions.length > 0) return;
        setFormValues((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
      
        try {
          const response = await axiosInstance.get(mcclistapiurl);
          const data = response.data;
      
          if (data.message === 'Success') {
            const mccList = data.data;
            const codes = mccList.map((mcc) => ({
              country_code: mcc.country_code,
              dial_code: mcc.code,
              mcc_id: mcc.id,
              label: `${mcc.name} (+${mcc.code})`,
              value: mcc.country_code,
              flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
            }));
      
            setFormValues((prevState) => ({
              ...prevState,
              countryOptions: codes,
            }));
            let code = ipCountryCode;
            const selectedCountryData = codes.find(
              (country) => country.country_code === code
            );
      
            if (selectedCountryData) {
              setFormValues((prevState) => ({
                ...prevState,
                mcc_id: selectedCountryData.mcc_id,
                dial_code: `+${selectedCountryData.dial_code}`,
                country_code: selectedCountryData.country_code,
              }));
      
              const maxLength =  updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
            }
          } else {
            console.error('Failed to fetch country codes');
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        } finally {
          setFormValues((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        }
      }
    
    }, [formValues.countryOptions.length, updateExampleNumber, showErrorToast,ipCountryCode]);
    const ip = useCallback(async() => {
        if(!ipCountryCode){
            await getUserIpCountryCode();
            fetchCountryCode();
        }else{
            fetchCountryCode();
        }
    },[ipCountryCode,getUserIpCountryCode,fetchCountryCode]);
    useEffect(()=>{
        if(formValues.forgotvalueselect === 'Mobile'){
        ip();
        }
    },[formValues.forgotvalueselect,ip]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        setError422('');
    };
    const customSingleValue = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
          />
          {/* {data.label} */}
        </div>
    );
    const customOption = (props) => {
        return (
          <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
              />
              {props.data.label}
            </div>
          </components.Option>
        );
    };
    const calculateWidth = (label) => {
        const tempDiv = document.createElement('p');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.fontSize = '14px';
        tempDiv.style.fontWeight = '400';
        tempDiv.style.whiteSpace = 'nowrap';
        tempDiv.style.margin = '0';
        document.body.appendChild(tempDiv);
    
        tempDiv.innerText = label;
        const newWidth = tempDiv.offsetWidth;
        document.body.removeChild(tempDiv);
    
        return newWidth;
      };
    const selectWidth = useCallback(() => {
        const label = formValues.dial_code || '';
        const newWidth = Math.round(calculateWidth(label));
        setSelwidth(newWidth + 82);
      }, [formValues.dial_code]);
    useEffect(() => {
        selectWidth();
      }, [selectWidth]);
    
      useEffect(() => {
        const handleResize = () => {
          selectWidth();
        };
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [selectWidth]);
    const handleSelectChange = (selectedOption) => {
        setFormValues({
          ...formValues,
          forgotvalueselect: selectedOption.value, 
          forgotemail:'',
          forgotmobile:'',
          forgotmccid:''
        });
    };
    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
        if (numericPastedText.startsWith('0')) {
            numericPastedText = numericPastedText.slice(1);
        }
  
        const maxLength = mobileInputRef.current ? mobileInputRef.current.getAttribute('maxlength') : 15;
        if (numericPastedText.length > maxLength) {
            numericPastedText = numericPastedText.slice(0, maxLength);
        }
  
        setFormValues((prev)=>({
            ...prev,username:numericPastedText
        }));
        setError422('');
        e.preventDefault();
    }
    const handleCountryChange = (selectedOption) => {
        const selectedCountryData = formValues.countryOptions.find(
            (country) => country.value === selectedOption.value
          );
      
          if (selectedCountryData) {
              setFormValues((prevState) => ({
                  ...prevState,
                  mcc_id: selectedCountryData.mcc_id,
                  dial_code: `+${selectedCountryData.dial_code}`,
                  country_code: selectedCountryData.country_code,
                }));
      
            const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
            if (maxLength !== null && mobileInputRef.current) {
              mobileInputRef.current.setAttribute('maxlength', maxLength);
            }
          }
          setError422('');
    }
    const check = useCallback(()=>{
        const select = formValues.forgotvalueselect === 'Email' || formValues.forgotvalueselect === 'Mobile';
        let values = false;
        if(formValues.forgotvalueselect === 'Email' && formValues.forgotemail){
            values = true;
        }else if(formValues.forgotvalueselect === 'Mobile' && formValues.forgotmobile){
            values = true;
        }else{
            values = false;
        }
        const valid = (
           select !== '' && values === true
        )
        setEnabled(valid);
    },[formValues.forgotvalueselect,formValues.forgotemail,formValues.forgotmobile]);
    useEffect(()=>{
    check();
    },[check]);
    const sendotp = async() => {
        let data ={};
        const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (formValues.forgotvalueselect === 'Email') {
            if(!gmailUsernamePattern.test(formValues.forgotemail)){
                setError422('Please Enter a valid email address');
                return false;
            }else{
                setError422('')
            }
            data = {
              username: formValues.forgotemail, 
            };
          } else if (formValues.forgotvalueselect === 'Mobile') {
            if(!/^\d+$/.test(formValues.forgotmobile)){
                setError422('Please Enter a valid Mobile Number');
                return false;
            }else{
                setError422('');
            }
            data = {
              username: formValues.forgotmobile, // Send mobile number if 'Mobile' is selected
              country_code: formValues.dial_code, // Include dial code for mobile
            };
          }
      try{
        setIsLoading4(true);
      const response = await axiosInstance.post(forgotpasswordsendotpapiurl,data);
      if(response.status === 200){
        setShowforgotpage(false);
        setShowforgotpageotp(true);
        setShowforgotpagepassword(false);
        setIsLoading4(false);
      }
      }catch(error){
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast)
      }
    }
    const cancel = () =>{
      setFormValues({
        ...formValues,
        forgotvalueselect:'',
        forgotemail:'',
        forgotmobile:'',
        forgotmccid:''
      });
      navigate('/');
    }
  return (
    <> 
       <div className='d-flex justify-content-center align-items-center'>
       <div className="forgotbox mt-4">
       {showforgotpage &&(
            <div className='resetpasspad'>
       <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
        <img src={resetpasswordlock} alt='passwordlock' className='resetppasslock' width={80} height={80}></img>
        <h6 className='mt-2 resetpassh6'>Reset Your Password</h6>
        <p className='resetpassp'>To reset your password, you need to provide the email or phone number with which it was registered.</p>
       </div>
       <label className='resetpasslabel'>Enter your email address or phone number</label>
       <Select options={forgotpreferoption} onChange={handleSelectChange} value={forgotpreferoption.find(option => option.value === formValues.forgotvalueselect)} styles={customStyles} />
        {formValues.forgotvalueselect === 'Email' ? (
        <input type='text' name='forgotemail' onChange={handleChange} value={formValues.forgotemail} className={`form-control forgot_input_40 ${formValues.forgotemail ? 'forgot_input_40_active' : 'forgot_input_40_not'}`} placeholder='Email address'></input>
         ) : (
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'absolute', left: 0,top:"16px",zIndex: 0 }}>
          <Select options={formValues.countryOptions} onChange={handleCountryChange} value={formValues.countryOptions.find(option => option.value === formValues.country_code)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={formValues.isLoading} styles={customStyles1}/>
          </div>
          <div style={{ height: '24px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '64%', transform: 'translateY(-50%)' }}></div>
          <p style={{ position: 'absolute', left:'78px', top: '66%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{formValues.dial_code}</p>
          <input type="text" className={`form-control forgot_input_40 ${formValues.forgotmobile ? 'forgot_input_40_active' : 'forgot_input_40'}`} id="mobile" ref={mobileInputRef} style={{ paddingLeft: `${selwidth + 18}px`, width: '100%' }} placeholder="Mobile Number" value={formValues.username} name="forgotmobile" onPaste={(e) => handlePaste(e)} onChange={handleChange}/>          
            </div>
         )}
         {error422 && (<div className='errors'>{error422}</div>)}
         <div className='text-end mt-3'>
            <button className='resetcancelbutton me-3' onClick={cancel}>Cancel</button>
            <button className={`resetsendcodebutton ${isenabled ? 'resetsendcodebutton_active' : 'resetsendcodebutton_disabled'}`} disabled={!isenabled} onClick={sendotp}>Send Code</button>
            </div>
            </div>
        )}
        {showforgotpageotp &&(
            <Forgototppage formValues={formValues} showforgotpageotp={showforgotpageotp} sendotp={sendotp} setShowforgotpage={setShowforgotpage} setShowforgotpageotp={setShowforgotpageotp} setShowforgotpagepassword={setShowforgotpagepassword} setIsLoading4={setIsLoading4}/>
        )}
        {showforgotpagepassword &&(
            <Forgotpasswordchange setIsLoading4={setIsLoading4}/>
        )}
            </div>
            </div>
    </>
  )
})

export default WithToast(Forgotpage);