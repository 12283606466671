import { fetchCountryListPending, fetchCountryListFulfilled, fetchCountryListRejected } from '../slices/countryslice';
import { countryList} from '../../../api/helper/apiurlhelper';
import { handleApiError} from '../../utils/utils'; 

export const fetchCountrys = (showErrorToast) => async (dispatch,getState) => {
    const { country } = getState();
    if (country.countrylistoptions.length > 0) return;
  try{
  dispatch(fetchCountryListPending());
  const response = await countryList();
  if (response.status === 200) {
    const mappedRelations = response.data.data.map((relation) => ({
      value: relation.id,
      label: relation.name,
    }));
    dispatch(fetchCountryListFulfilled(mappedRelations));
  }
  }catch(error){
    handleApiError(error, null, null, true, showErrorToast);
    dispatch(fetchCountryListRejected(error.response?.data || error.message));
  }
};
