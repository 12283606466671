import React, { useCallback, useEffect, useState } from 'react'
import WithToast from '../../api/hooks/withtoasthook';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import resetpasswordlock from '../../images/Reset_Password.svg';
import { handleApiError, validateAndSetMobileChange, validateEmail } from '../../api/utils/utils';
import PhoneInputWithCountrySelector from '../../components/phoneinputcountryselector';
import { useCountryData1 } from '../../api/hooks/mobilecountryhook';
import Button from '../../components/savebutton';
import { forgotSendOtp } from '../../api/helper/apiurlhelper';
import Forgototppagenew from './forgototppagenew';
const forgotpreferoption = [
    { value: 'Email', label: 'Email' },
    { value: 'Mobile', label: 'Mobile' },
];
function Forgotpagenew({showErrorToast,setIsLoading4}) {
  const navigate = useNavigate();  
  const [selectedCountry, setSelectedCountry] = useState({country_code: "",dial_code: "",mcc_id: "",mobile_length: "",mobile_starting_digits: "",mobileChange:"",numberinvalid:false,forgotvalueselect:'Email',email:'',});
  const [showscreen,setShowscreen] = useState({enabled:false,forgototp:true,forgotverify:false,forgotpass:false});
  const {countryOptions1,isLoading1,loadip} = useCountryData1();
  const [error422,setError422] = useState('');
  const handleInputChange = (e) => {
    const { name, value,} = e.target;
     setSelectedCountry((prevValues) => ({
        ...prevValues,
        [name]:  value,
    }));
    if(name === 'mobileChange'){
        validateAndSetMobileChange(value, {
            setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
            setError:setError422,
            setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
            mobile_length: selectedCountry.mobile_length,             
            mobile_starting_digits: selectedCountry.mobile_starting_digits 
        });
    }
   }
   const handleSelectChange = (selected) => {
    setSelectedCountry((prev)=>({...prev,forgotvalueselect:selected.value,email:'',country_code:'',dial_code:'',mcc_id:'',mobile_length:'',mobile_starting_digits:'',mobileChange:'',numberinvalid:false}));
    setError422('');
  }; 

  useEffect(()=>{
     if(selectedCountry.forgotvalueselect === 'Mobile'){
          loadip(true, (data) => {
              if(data){
              setSelectedCountry((prev)=>({
                ...prev,
                country_code: data?.country_code,
                dial_code: data?.dial_code,
                mcc_id: data?.mcc_id,
                mobile_length: data?.mobile_length,
                mobile_starting_digits: data?.mobile_starting_digits,
              }));
            }
            });
        }
   },[selectedCountry.forgotvalueselect]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width:'100%',
      height: '40px',
      border: 'none',
      boxShadow: 'none',
      background: '#EDEDED',
      marginTop:'.5rem',
      borderRadius : '4px',
      }),
      dropdownIndicator: (provided) => ({
      ...provided,
      color:'black',
      padding: '0 8px',
      }),
      indicatorSeparator: () => ({
      display: 'none',
      }),
      singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      fontSize:'14px',
      lineHeigt:'20px',
      fontWeight:500,
      color:'#000000'
      }),
      valueContainer: (provided) => ({
      ...provided,
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '15px',
      
      }),
      input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
      }),
      placeholder: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
      }),
      menu: (provided) => ({
      ...provided,
      minWidth: '300px',})  
    }
     const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
        // const dialCodeWithoutPlus = selectedCountry.dial_code.replace('+', '');
        const dialCodeWithoutPlus = typeof selectedCountry?.dial_code === 'string' ? selectedCountry?.dial_code.replace('+', '') : '';

        if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
            numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
        }
        validateAndSetMobileChange(numericPastedText, {
            setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
            setError: setError422,
            setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
            mobile_length: selectedCountry.mobile_length,             
            mobile_starting_digits: selectedCountry.mobile_starting_digits 
        });
        e.preventDefault();
        };
        const handleCountryChange = (selectedOption) => {
        const selectedCountryData = countryOptions1.find(
            (country) => country.value === selectedOption.value
        );
        if (selectedCountryData) {
            const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
            setSelectedCountry((prev)=>({
            ...prev,
            country_code:country_code,
            dial_code: dial_code,
            mcc_id: mcc_id,
            mobile_length: mobile_length,
            mobile_starting_digits: mobile_starting_digits,
            }));
        
            validateAndSetMobileChange(selectedCountry.mobileChange, {
            setMobileChange:(val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
            setError: setError422,
            setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
            mobile_length : mobile_length,
            mobile_starting_digits : mobile_starting_digits,
            });
        }
        }; 
        const check = useCallback(()=>{
        const select = selectedCountry.forgotvalueselect === 'Email' || selectedCountry.forgotvalueselect === 'Mobile';
        let values = false;
        if(selectedCountry.forgotvalueselect === 'Email' && selectedCountry.email){
            values = true;
        }else if(selectedCountry.forgotvalueselect === 'Mobile' && selectedCountry.mobileChange){
            values = true;
        }else{
            values = false;
        }
        const valid = (
            select !== '' && values === true
        )
        setShowscreen((prev)=>({...prev,enabled:valid}));
        },[selectedCountry.forgotvalueselect,selectedCountry.email,selectedCountry.mobileChange]);
        useEffect(()=>{
        check();
        },[check]);
        const sendcode = async() => {
           let data;
           const isValid = validateEmail(selectedCountry.email);
           if(selectedCountry.forgotvalueselect === 'Email'){
             if(!isValid){
                setError422('Please Enter a valid email address');
                return;
             }else{
                data = {
                    username: selectedCountry.email, 
                };
             }
           }else if(selectedCountry.forgotvalueselect === 'Mobile'){
                data = {
                    username : selectedCountry.mobileChange,
                    mcc_id : selectedCountry.mcc_id
                }
           }
           setError422('');
           try{
           setIsLoading4(true);
           const response = await forgotSendOtp(data);
           if(response.status === 200){
              setShowscreen((prev)=>({...prev,forgotverify:true,forgototp:false}));
           }
           }catch(error){
              handleApiError(error,setIsLoading4,setError422,false,showErrorToast)
           }finally{
              setIsLoading4(false) 
           }
        } 
  return (
    <>
       <div className='d-flex justify-content-center align-items-center'>
       <div className="forgotbox mt-4">
        {showscreen.forgototp && (
       <div className='resetpasspad'>
       <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
       <img src={resetpasswordlock} alt='passwordlock' className='resetppasslock' width={80} height={80}></img>
       <h6 className='mt-2 resetpassh6'>Reset Your Password</h6>
       <p className='resetpassp'>To reset your password, you need to provide the email or phone number with which it was registered.</p>
       </div>
       <label className='resetpasslabel'>Enter your email address or phone number</label>
       <Select  options={forgotpreferoption}  value={forgotpreferoption.find(option => option.value === selectedCountry.forgotvalueselect)}  isSearchable={true} placeholder="Select Country" onChange={handleSelectChange} styles={customStyles} />
       {selectedCountry.forgotvalueselect === 'Email' ? (
        <input type='email' name='email' placeholder='Email address' onChange={handleInputChange} value={selectedCountry.email} className={`form-control forgot_input_40 mt-3 ${selectedCountry.email ? 'forgot_input_40_active' : 'forgot_input_40_not'}`}></input>
       ) : (
        <div className='position-relative mt-3'>
        <PhoneInputWithCountrySelector countryOptions={countryOptions1} name='mobileChange'  handleCountryChange={handleCountryChange} dialCode={selectedCountry.dial_code} mobileChange={selectedCountry.mobileChange} handlePhoneNumberChange={handleInputChange}  handlePaste={handlePaste} countryCode={selectedCountry.country_code} walletprofileinput = 'forgot_input_40' walletprofileinputactive='forgot_input_40_active' isLoading={isLoading1} placeholder="Mobile Number" /> 
        </div>
       )}
         {error422 && (<div className='errors'>{error422}</div>)}
        <div className='d-flex align-items-center justify-content-end mt-3'> 
        <Button defaultclassname='resetcancelbutton me-3' onClick={()=>navigate('/auth')} active={true} label='Cancel'/> 
        <Button defaultclassname='resetsendcodebutton ' onClick={sendcode} enabled={showscreen.enabled} activeclassname='resetsendcodebutton_active' disabledclassname='resetsendcodebutton_disabled' label='Send Code'/>
        </div>
       </div>
        )}
        {showscreen.forgotverify && (
            <Forgototppagenew formValues={selectedCountry} setIsLoading4={setIsLoading4} sendcode={sendcode} showscreen={showscreen} showErrorToast={showErrorToast} setShowscreen={setShowscreen}/> 
        )}
       </div>
       </div>
    </>
  )
}

export default WithToast(Forgotpagenew);