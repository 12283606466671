import axiosInstance from "../utils/axiosutils"
import {country_codeapi, countryselectapi, forgotpasswordotpverifyapi, forgotpasswordsendotpapi, loginapi, loginwithotpapi, loginwithotpverifyapi, logoutallapi, logoutapi, logoutremoveapi, refreshapi, sidapi, signupapi, signupotpsendapi, signupotpverifyapi, signupproceedapi, signupupdateapi, signupverifyapi} from './urlhelper';
const sidapiurl = sidapi();
const logoutremoveapiurl = logoutremoveapi();
const logoutapiurl = logoutapi();
const refreshapiurl = refreshapi();
const logoutallapiurl = logoutallapi();
const countrycodeurl = country_codeapi();
const signupapiurl = signupapi();
const countryselectapiurl = countryselectapi();
const loginUrl = loginapi();
const loginwithotpapiurl = loginwithotpapi();
const loginwithotpverifyapiurl = loginwithotpverifyapi();
const signupverifyapiurl = signupverifyapi();
const forgotpasswordsendotpapiurl = forgotpasswordsendotpapi();
const forgotpasswordotpverifyapiurl = forgotpasswordotpverifyapi();
const signupotpsendapiurl = signupotpsendapi();
const signupotpverifyapiurl= signupotpverifyapi();
const signupproceedapiurl = signupproceedapi();
const signupupdateapiurl = signupupdateapi();
export const sid = async() => {
    return await axiosInstance.get(sidapiurl);
}
export const accountremove = async (authuser) => {
    const config = {
      headers: {
        'X-Authuser': authuser,  // Pass the dynamic X-Authuser header
        'Content-type': 'application/json',
        'Accept': 'application/json',
      },
      withCredentials: true
    };
  
    return await axiosInstance.post(logoutremoveapiurl, null, config);
};
export const accountlogout = async(authuser) => {
    const config = {
        headers: {
          'X-Authuser': authuser,  // Pass the dynamic X-Authuser header
          'Content-type': 'application/json',
          'Accept': 'application/json',
        },
        withCredentials: true
      };
    
      return await axiosInstance.post(logoutapiurl, null, config);
}
export const accountrefresh = async(authuser,data) => {
  const config = {
    headers: {
      'X-Authuser': authuser,  // Pass the dynamic X-Authuser header
      'Content-type': 'application/json',
      'Accept': 'application/json',
    },
    withCredentials: true
  };

  return await axiosInstance.post(refreshapiurl, data, config);
}
export const accountlogoutall = async(data) => {
  return await axiosInstance.post(logoutallapiurl,data);
}
export const mcc_list_ip = async() => {
  return await axiosInstance.get(countrycodeurl);
}
export const countryList = async() => {
  return await axiosInstance.get(countryselectapiurl);
} 
export const Signup = async(data) => {
  return await axiosInstance.post(signupapiurl,data);
}
export const SignupUpdate = async(data) => {
  return await axiosInstance.post(signupupdateapiurl,data);
}
export const signupverify = async(data) => {
  return await axiosInstance.post(signupverifyapiurl,data)
}
export const Login = async(data) => {
  return await axiosInstance.post(loginUrl,data);
}
export const loginWithSendOtp = async(data)=> {
  return await axiosInstance.post(loginwithotpapiurl,data);
}
export const loginWithVerifyOtp = async(data) => {
  return await axiosInstance.post(loginwithotpverifyapiurl,data);
}
export const forgotSendOtp = async(data) => {
  return await axiosInstance.post(forgotpasswordsendotpapiurl,data);
}
export const forgotVerifyOtp =async(data) => {
  return await axiosInstance.post(forgotpasswordotpverifyapiurl,data);
}
export const signupOtpSend = async(data) => {
  return await axiosInstance.post(signupotpsendapiurl,data);
}
export const signupOtpVerify = async(data) => {
  return await axiosInstance.post(signupotpverifyapiurl,data)
}
export const signupProceed = async(data) => {
  return await axiosInstance.post(signupproceedapiurl,data)
}