import React, { useCallback, useState } from "react";
import edit from "../images/Group 427319049.svg";
import Cookies from "js-cookie";
import user from "../images/Group 427319048.svg";
import checkcirecle from "../images/check-circle.svg";
import pen from "../images/pen (1).svg";
import {
  sid,
  signupOtpSend,
  signupOtpVerify,
  signupProceed,
} from "../api/helper/apiurlhelper";
import { handleApiError, successresponseerror } from "../api/utils/utils";
import Button from "../components/savebutton";
function Signupverify({
  formValues,
  setFormValues,
  setIsLoading4,
  showErrorToast,
  selectedCountry,
  setIstrue,
  setShowscreen,
}) {
  const [showMessage, setShowMessage] = useState(false);
  const [otpstate, setOtpstate] = useState({
    emailotp: "",
    mobileotp: "",
    emailshow: false,
    mobileshow: false,
    emailverify: false,
    mobileverify: false,
    countdownemail:120,
    countdownmobile:120
  });
  const [error422, setError422] = useState("");
  const userAccounts = useCallback(async () => {
    sid()
      .then((res) =>
        setFormValues((prev) => ({ ...prev, uservalues: res.data.signup }))
      )
      .catch((error) =>
        handleApiError(error, null, null, true, showErrorToast)
      );
  }, []);
  const back = () => {
    setIstrue(false);
    setShowscreen((prev) => ({ ...prev, verrifyscreen: false, signupscreen: true }));
  };
  const handleinputchange = (e) => {
    const { name, value } = e.target;
    setOtpstate((prev) => ({ ...prev, [name]: value }));
    setError422("");
  };
  const startCountdown = () => {
    setOtpstate((prev)=>({...prev,countdownemail:120}))
    let timer = setInterval(() => {
      setOtpstate((prevState) => {
        if (prevState.countdownemail <= 1) {
          clearInterval(timer);
          return { ...prevState, countdownemail: 0 };
        }
        return { ...prevState, countdownemail: prevState.countdownemail - 1 };
      });
    }, 1000);
    return timer;
  };
  const startCountdown1 = () => {
    setOtpstate((prev)=>({...prev,countdownmobile:120}))
    let timer = setInterval(() => {
      setOtpstate((prevState) => {
        if (prevState.countdownmobile <= 1) {
          clearInterval(timer);
          return { ...prevState, countdownmobile: 0 };
        }
        return { ...prevState, countdownmobile: prevState.countdownmobile - 1 };
      });
    }, 1000);
    return timer;
  };  
  const sendOtp = async (value) => {
    try {
      setIsLoading4(true);
      let data, cookieName;
      if (value === "email") {
        data = {
          verify: "email",
          email: formValues.uservalues.email,
        };
        cookieName = "otpHashemail";
      } else {
        data = {
          verify: "mobile",
          mobile: formValues.uservalues.mobile,
          mcc_id: formValues.uservalues.mcc_id,
        };
        cookieName = "otpHashmobile";
      }
      const response = await signupOtpSend(data);
      if (response.status === 200) {
        const hashValue = response.data.data.hash;
        Cookies.set(cookieName, hashValue, {
          expires: 5 / 1440,
          secure: true,
          sameSite: "Strict",
        });
        if(value === 'email'){
            startCountdown();
        }else{
            startCountdown1();
        }
        setOtpstate((prev) => ({
          ...prev,
          emailshow: value === "email" ? true : false,
          mobileshow: value === "mobile" ? true : false,
          emailotp: "",
          mobileotp: "",
        }));
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    } finally {
      setIsLoading4(false);
    }
  };
  const otpEmailHash = Cookies.get("otpHashemail");
  const otpMobileHash = Cookies.get("otpHashmobile");
  const signupverify = async (value) => {
    try {
      setIsLoading4(true);
      let data;
      if (value === "email") {
        data = {
          verify: "email",
          otp: otpstate.emailotp,
          hash: otpEmailHash,
        };
      } else {
        data = {
          verify: "mobile",
          otp: otpstate.mobileotp,
          hash: otpMobileHash,
        };
      }
      const response = await signupOtpVerify(data);
      if (response.status === 200) {
        setOtpstate((prev)=>({...prev,emailotp:'',mobileotp:'',emailshow:false,mobileshow:false}))
        await userAccounts();
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    } finally {
      setIsLoading4(false);
    }
  };
  const proceed = async () => {
    try {
      setIsLoading4(true);  
      const data = {
        device_type: "web",
      };
      const response = await signupProceed(data);
      if (response.status === 200) {
        await successresponseerror(response, "response");
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    } finally {
      setIsLoading4(false);
    }
  };
  return (
    <>
      <div className="logininputboxcenter flex-column align-items-center">
        <div className="position-relative">
          <img src={user} alt="user"></img>
          <div
            className="verifyimageedit"
            onClick={() => setShowMessage(true)}
            onMouseEnter={() => setShowMessage(true)}
            onMouseLeave={() => setShowMessage(false)}
          >
            <img src={edit} alt="edit"></img>
          </div>
          {showMessage && (
            <div className="coming-soon-tooltip">Coming Soon</div>
          )}
        </div>
        <div className="w-100 mt-3 d-flex justify-content-center align-items-center  verifydiv">
          Verify Your Contacts
        </div>
        <div className="d-flex justify-content-between align-items-center rounded mt-3 verify_input_div">
          <div className="d-flex align-items-center gap-2 width70">
            <p className="mb-0 text-truncate">{formValues.email}</p>
            <img
              src={pen}
              alt="pen"
              className="cursor-pointer"
              onClick={back}
            ></img>
          </div>
          <div className="width30 text-end">
            {formValues.uservalues.email_verifed ? (
              <img src={checkcirecle} alt="checck"  className="me-2"
              style={{ height: "18px" }}></img>
            ) : (
              <button onClick={() => sendOtp("email")}>Verify</button>
            )}
          </div>
        </div>
        {otpstate.emailshow && (
          <>
            <div className="verifycenter mt-3">
              <input
                type="text"
                name="emailotp"
                className="form-control logininputsign"
                value={otpstate.emailotp}
                onChange={handleinputchange}
                placeholder="Enter OTP sent to you"
              ></input>
              <button
                className="confirmbutton"
                onClick={() => signupverify("email")}
              >
                Confirm
              </button>
            </div>
            <div className="w-100 d-flex justify-content-end" style={{maxWidth:'446px'}}>
            <strong className={`${otpstate.countdownemail > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: otpstate.countdownemail > 0 ? 'not-allowed' : 'pointer' }} onClick={otpstate.countdownemail <= 0 ? () => sendOtp("email") : null}> {`Resend ${otpstate.countdownemail > 0 ? `${otpstate.countdownemail}s` : `${otpstate.countdownemail}s`}`} </strong>
            </div>
          </>
        )}
        <div className={`d-flex justify-content-between align-items-center rounded  verify_input_div ${otpstate.emailshow ? '' : 'mt-3'}`}>
          <div className="d-flex align-items-center gap-2 width70">
            <p className="mb-0 text-truncate">{`+${selectedCountry.dial_code} ${selectedCountry.mobileChange}`}</p>
            <img
              src={pen}
              alt="pen"
              onClick={back}
              className="cursor-pointer"
            ></img>
          </div>
          <div className="width30 text-end">
            {formValues.uservalues.mobile_verifed ? (
              <img
                src={checkcirecle}
                className="me-2"
                style={{ height: "18px" }}
                alt="checck"
              ></img>
            ) : (
              <button onClick={() => sendOtp("mobile")}>Verify</button>
            )}
          </div>
        </div>
        {otpstate.mobileshow && (
          <>
            <div className="verifycenter mt-3">
              <input
                type="text"
                name="mobileotp"
                className="form-control logininputsign"
                value={otpstate.mobileotp}
                onChange={handleinputchange}
                placeholder="Enter OTP sent to you"
              ></input>
              <button
                className="confirmbutton"
                onClick={() => signupverify("mobile")}
              >
                Confirm
              </button>
            </div>
            <div className="w-100 d-flex justify-content-end" style={{maxWidth:'446px'}}>
            <strong className={`${otpstate.countdownmobile > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: otpstate.countdownmobile > 0 ? 'not-allowed' : 'pointer' }} onClick={otpstate.countdownmobile <= 0 ? () => sendOtp("mobile") : null}>{`Resend ${otpstate.countdownmobile > 0 ? `${otpstate.countdownmobile}s` : `${otpstate.countdownmobile}s`}`}</strong>
            </div>
          </>
        )}
        {error422 && <div className="errors">{error422}</div>}
        <Button
          defaultclassname="savedefault mt-3"
          onClick={proceed}
          enabled={
            formValues.uservalues.email_verifed ||
            formValues.uservalues.mobile_verifed
          }
          activeclassname="savedefault_active"
          disabledclassname="savedefault_disabled"
          label="Proceed"
        />
      </div>
    </>
  );
}

export default Signupverify;
