import React, { useState } from "react";
import eyeslash from '../images/eye_slash-01.png';
import eye from '../images/eye-01.png';
import eyegrey from '../images/eye-01pass.png';
import eyeslashgrey from '../images/eye-slash-01passw.png';
const PasswordToggle = ({ password, onToggle }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
    onToggle(!isVisible);
  };

  return (
    <div className="seperatediv seperatediv1 cursor-pointer" onClick={handleToggle}>
      {isVisible ? (
        password ? (
          <img src={eye} className="eyeslash" alt="Hide Password" />
        ) : (
          <img src={eyegrey} className="eyeslash" alt="Hide Password" />
        )
      ) : password ? (
        <img src={eyeslash} className="eyeslash" alt="Show Password" />
      ) : (
        <img src={eyeslashgrey} className="eyeslash" alt="Show Password" />
      )}
    </div>
  );
};

export default PasswordToggle;
