import React,{useState,useRef,useEffect, useCallback} from 'react';
import { useCountryData1 } from '../api/hooks/mobilecountryhook';
import { handleApiError, successresponseerror, validateAndSetMobileChange, validateEmail } from '../api/utils/utils';
import PhoneInputWithCountrySelector from '../components/phoneinputcountryselector';
import Button from '../components/savebutton';
import { Link } from 'react-router-dom';
import { Login } from '../api/helper/apiurlhelper';
import PasswordToggle from '../components/passwordtoggle';
function Loginwithpasswordnew({showErrorToast,setIsLoading4,toggleLoginForms}) {
  const [selectedCountry, setSelectedCountry] = useState({country_code: "",dial_code: "",mcc_id: "",mobile_length: "",mobile_starting_digits: "",mobileChange:"",numberinvalid:false,password:'',email:''});
  const [showscreen,setShowscreen] = useState({passwordshow:false,showmobileinput:false,islogged:false});
  const [inlineerror,setInlineError] = useState({mobileerror:'',passworderror:''});
  const {countryOptions1,isLoading1,loadip} = useCountryData1();
  // const mobileInputRef = useRef(null);
  const [error422,setError422] = useState('');
  // const inputRef = useRef(null);
//   useEffect(() => {
//     if (showscreen.showmobileinput && mobileInputRef.current) {
//         mobileInputRef.current.focus();
//     } else if (inputRef.current) {
//         inputRef.current.focus();
//     }
// }, [showscreen.showmobileinput]);
   useEffect(()=>{
    loadip(true, (data) => {
      if(data){
      setSelectedCountry((prev)=>({
        ...prev,
        country_code: data?.country_code,
        dial_code: `${data?.dial_code}`,
        mcc_id: data?.mcc_id,
        mobile_length: data?.mobile_length,
        mobile_starting_digits: data?.mobile_starting_digits,
      }));
    }
    });
   },[])
  const handleInputChange = (e) => {
    const { name, value,} = e.target;
    setSelectedCountry((prevValues) => ({
        ...prevValues,
        [name]: value.trim(),
     }));
     if(name === 'email'){
      setInlineError((prev)=>({...prev,mobileerror:''}));
     }
        // const value = e.target.value;
        // if(/^\+?\d+$/.test(value)){
        //  setShowscreen((prev)=>({...prev,showmobileinput:true}));
        //   validateAndSetMobileChange(value, {
        //     setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
        //     setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
        //     setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
        //     mobile_length: selectedCountry.mobile_length,             
        //     mobile_starting_digits: selectedCountry.mobile_starting_digits 
        //     });
        // }else{
          //  setShowscreen((prev)=>({...prev,showmobileinput:false}));
          //  setSelectedCountry((prev)=>({...prev,mobileChange:value}));
          //  setInlineError((prev)=>({...prev,mobileerror:''}));
        // } 
        setError422('');
    } 
     const handlePhoneNumberChange = (value) => {
            validateAndSetMobileChange(value, {
              setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
              setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
              setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
              mobile_length: selectedCountry.mobile_length,             
              mobile_starting_digits: selectedCountry.mobile_starting_digits 
            });
          };
    const handlePaste = (e) => {
            const pastedText = e.clipboardData.getData('text');
            let numericPastedText = pastedText.replace(/[^0-9]/g, '');
            // const dialCodeWithoutPlus = selectedCountry.dial_code.replace('+', '');
        const dialCodeWithoutPlus = typeof selectedCountry?.dial_code === 'string' ? selectedCountry?.dial_code.replace('+', '') : '';

            if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
                numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
            }
            validateAndSetMobileChange(numericPastedText, {
              setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
              setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
              setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
              mobile_length: selectedCountry.mobile_length,             
              mobile_starting_digits: selectedCountry.mobile_starting_digits 
            });
            e.preventDefault();
          };
          const handleCountryChange = (selectedOption) => {
            const selectedCountryData = countryOptions1.find(
                (country) => country.value === selectedOption.value
            );
            if (selectedCountryData) {
              const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
              setSelectedCountry((prev)=>({
                ...prev,
                country_code:country_code,
                dial_code: dial_code,
                mcc_id: mcc_id,
                mobile_length: mobile_length,
                mobile_starting_digits: mobile_starting_digits,
              }));
          
              validateAndSetMobileChange(selectedCountry.mobileChange, {
                setMobileChange:(val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
                setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
                setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
                mobile_length : mobile_length,
                mobile_starting_digits : mobile_starting_digits,
              });
            }
        }; 
        useEffect(() => {
          const valid = (
              (selectedCountry.mobileChange !== '' || selectedCountry.email !== '') &&
              selectedCountry.password !== '' &&
              (showscreen.showmobileinput ? selectedCountry.numberinvalid === true : true)
          );
      
          setShowscreen((prev) => ({ ...prev, islogged: valid }));
      }, [selectedCountry.mobileChange, selectedCountry.email, selectedCountry.password, selectedCountry.numberinvalid, showscreen.showmobileinput]);
      
        const signin = async(event) => {
         event.preventDefault();
         let data;
         const isEmailValid = validateEmail(selectedCountry.email);
         if(!showscreen.showmobileinput){
          setInlineError((prevState) => ({
            ...prevState,
            mobileerror: isEmailValid ? '' : 'Please enter a valid email address.',
          }));
          if(!isEmailValid) return;
          data={
            username:selectedCountry.email,
            password:selectedCountry.password,
            device_type:'web',
          }
         }else{
            data = {
            username: selectedCountry.mobileChange,
              password: selectedCountry.password,
              mcc_id: selectedCountry.mcc_id,
              device_type: 'web'
            } 
         }
        //  if (/^\+?\d+$/.test(selectedCountry.mobileChange)){
        //     data = {
        //     username: selectedCountry.mobileChange,
        //     password: selectedCountry.password,
        //     mcc_id: selectedCountry.mcc_id,
        //     device_type: 'web'
        //     };
        //  }else if(isEmailValid){
        //     data = {
        //         username: selectedCountry.mobileChange,
        //         password: selectedCountry.password,
        //         device_type: 'web'
        //       };
        //  }else{
        //     setInlineError((prev) => ({ ...prev, mobileerror: 'Invalid Email Address' }));
        //     return;
        //  }
         try{
          setIsLoading4(true);
           const response = await Login(data);
           if(response.status === 200){
             successresponseerror(response,'response');
           }
         }catch(error){
            handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
         }finally{
            setIsLoading4(false);
         }
        }
  return (
   <>
   <form autoComplete='off' className='w-100'>
   <div className='logininputboxcenter mt-4'>
    {!showscreen.showmobileinput ? (
    <div className={`newinput ${selectedCountry.email ? 'newinput_active' : ''}`}>
    <input type='email' autoComplete='off' name='email' placeholder='Email' value={selectedCountry.email} onChange={handleInputChange}  className={`form-control logininputsigninside input_width ${selectedCountry.email ? 'logininputsigninside-active' : ''}`} ></input>
    <div className='seperatediv' ><p className='ptag cursor-pointer' onClick={() => {
  if (isLoading1) return;
  setShowscreen((prev) => ({ ...prev, showmobileinput: true }));
}}
>Login With Mobile</p></div>
    </div>
    ) : (
     <div className={`newinput position-relative ${selectedCountry.mobileChange ? 'newinput_active' : ''}`}>   
     <PhoneInputWithCountrySelector countryOptions={countryOptions1} name='mobileChange'  handleCountryChange={handleCountryChange} dialCode={selectedCountry.dial_code} mobileChange={selectedCountry.mobileChange} handlePhoneNumberChange={(e)=>handlePhoneNumberChange(e.target.value)}  handlePaste={handlePaste} countryCode={selectedCountry.country_code} walletprofileinput = 'logininputsigninside input_width' walletprofileinputactive='logininputsigninside-active' isLoading={isLoading1} placeholder="Mobile Number" /> 
     <div className='seperatediv' ><p className='ptag cursor-pointer' onClick={()=>setShowscreen((prev)=>({...prev,showmobileinput:false}))}>Login With Email</p></div>
     </div>   
    )}
    {inlineerror.mobileerror &&  <div className="errors">{inlineerror.mobileerror}</div>}
    <div className={`newinput mt-3 ${selectedCountry.password ? 'newinput_active' : ''}`}>
    <input type={showscreen.passwordshow ? 'text' : 'password'} autoComplete='new-password' name='password' placeholder='Password' value={selectedCountry.password} onChange={handleInputChange} className={`form-control logininputsigninside  input_passwidth ${selectedCountry.password ? 'logininputsigninside-active' : ''}`}></input> 
    <PasswordToggle password={selectedCountry.password}  onToggle={(visible) => setShowscreen((prev) => ({ ...prev, passwordshow: visible }))}/>
    </div>
    <div className='w-100 d-flex justify-content-end widthmax '>
    <Link to='/auth/forgot'><p className="custom-text-style cursor-pointer">Forgot Password?</p></Link>
    </div>
    {error422 && (<div className='errors'>{error422}</div>)}
    <Button defaultclassname='savedefault' onClick={signin} enabled={showscreen.islogged} activeclassname='savedefault_active' disabledclassname='savedefault_disabled' label='Sign in'/>
    </div>
   </form>
   </>
  )
}

export default Loginwithpasswordnew;