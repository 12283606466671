import React,{useState,useCallback,useRef,useEffect, memo} from 'react';
import WithToast from '../api/hooks/withtoasthook';
import { countrystyle, handleApiError, process_params, selectWidth, validateAndSetMobileChange } from '../api/utils/utils';
import { loginapi } from '../api/helper/urlhelper';
import eyeslash from '../images/eye_slash-01.png';
import eye from '../images/eye-01.png';
import eyegrey from '../images/eye-01pass.png';
import eyeslashgrey from '../images/eye-slash-01passw.png';
import axiosInstance from '../api/utils/axiosutils';
import Select, { components } from 'react-select';
import { Link } from 'react-router-dom';
import { useCountryData } from '../api/hooks/mobilehook';
import useMenuHeight from '../api/hooks/useMenuheight';
const loginUrl = loginapi();
const Loginwithpassword = memo(({showErrorToast,setShowForm1,setShowForm2,setShowForm3,toggleLoginForms,setIsLoading4,myaccountdomainurl})=>{
    const mobileInputRef = useRef(null);
    const inputRef = useRef(null);
    const [showCountryInput, setShowCountryInput] = useState(false);
    const [islogged,setIslogged] = useState(false);
    const [isCountryCodeFetched, setIsCountryCodeFetched] = useState(false);
    const [mobileChange, setMobileChange] = useState('');
    const [numberinvalid,setNumberinvalid] = useState(false);
    const [formValues,setFormValues] = useState({
      password:'',showPassword:false
    });
    const {
      mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
      loadCountryByIp,
    } = useCountryData();
    const [error,setError] = useState({
        usernameerror:'',passworderror:''
    })
    const [error422,setError422] = useState('');
    // const { ipCountryCode, getUserIpCountryCode } = useIpContext();
    // const updateExampleNumber = useCallback((iso2) => {
    //     if (window.intlTelInputUtils) {
    //       try {
    //         const exampleNumber = window.intlTelInputUtils.getExampleNumber(
    //           iso2,
    //           true,
    //           window.intlTelInputUtils.numberFormat.E164
    //         );
    //         const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
    //         const maxLength = cleanedNumber.length;
    //         return maxLength;
    //       } catch (error) {
    //         console.error('Error fetching example number for country code:', iso2, error);
    //         return 15; // Fallback to a default length
    //       }
    //     } else {
    //       console.error('intlTelInputUtils is not loaded');
    //       return 15; // Fallback to a default length
    //     }
    //   }, []);
    //   const fetchCountryCode = useCallback(async () => {
    //     if (ipCountryCode){ 
    //     if (formValues.countryOptions.length > 0) return;
    //     setFormValues((prevState) => ({
    //       ...prevState,
    //       isLoading: true,
    //     }));
      
    //     try {
    //       const response = await axiosInstance.get(mcclistapiurl);
    //       const data = response.data;
      
    //       if (data.message === 'Success') {
    //         const mccList = data.data;
    //         const codes = mccList.map((mcc) => ({
    //           country_code: mcc.country_code,
    //           dial_code: mcc.code,
    //           mcc_id: mcc.id,
    //           label: `${mcc.name} (+${mcc.code})`,
    //           value: mcc.country_code,
    //           flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
    //         }));
      
    //         setFormValues((prevState) => ({
    //           ...prevState,
    //           countryOptions: codes,
    //         }));
    //         let code = ipCountryCode;
    //         const selectedCountryData = codes.find(
    //           (country) => country.country_code === code
    //         );
      
    //         if (selectedCountryData) {
    //           setFormValues((prevState) => ({
    //             ...prevState,
    //             mcc_id: selectedCountryData.mcc_id,
    //             dial_code: `+${selectedCountryData.dial_code}`,
    //             country_code: selectedCountryData.country_code,
    //           }));
      
    //           const maxLength =  updateExampleNumber(selectedCountryData.country_code.toLowerCase());
    //           if (maxLength !== null && mobileInputRef.current) {
    //             mobileInputRef.current.setAttribute('maxlength', maxLength);
    //           }
    //         }
    //       } else {
    //         console.error('Failed to fetch country codes');
    //       }
    //     } catch (error) {
    //       handleApiError(error, null, null, true, showErrorToast);
    //     } finally {
    //       setFormValues((prevState) => ({
    //         ...prevState,
    //         isLoading: false,
    //       }));
    //     }
    //   }
    
    // }, [formValues.countryOptions.length, updateExampleNumber, showErrorToast,ipCountryCode]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            login(e);
        }
    };
    const togglePasswordVisibility = () => {
      setFormValues((prev) => ({
        ...prev,
        showPassword: !prev.showPassword,
      }));
    }
    useEffect(() => {
        if (showCountryInput) {
            mobileInputRef.current?.focus();
        } else {
            inputRef.current?.focus();
        }
    }, [showCountryInput]);
    const handleUsernameChange = async(e) => {
        let inputUsername = e.target.value.replace(/\s/g, '');
        if (/^\+?\d+$/.test(inputUsername)) {
            if (!isCountryCodeFetched) {
                setShowCountryInput(true);
                loadCountryByIp(true);
                setIsCountryCodeFetched(true); 
            }
            validateAndSetMobileChange(inputUsername, {
              setMobileChange: setMobileChange,
              setError:  (errorMsg) => setError((prev) => ({ ...prev, usernameerror: errorMsg })),
              setNumberInvalid: setNumberinvalid,
              mobile_length: mobile_length,             
              mobile_starting_digits: mobile_starting_digits 
            });
        } else {
            setShowCountryInput(false);
            setIsCountryCodeFetched(false);
            setMobileChange(inputUsername);
             setError((prev)=>({
            ...prev,usernameerror:'',passworderror:''
        }));
        }
        setError422('');
    };
    const handleCountryChange = (selectedOption) => {
      const selectedCountryData = countryOptions.find(
          (country) => country.value === selectedOption.value
      );
      if (selectedCountryData) {
        const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
    
        setMccId(mcc_id);
        setDialCode(`+${dial_code}`);
        setCountryCode(country_code);
        setMobile_length(mobile_length);
        setMobile_starting_digits(mobile_starting_digits);
    
        // Apply validation to existing mobile number
        validateAndSetMobileChange(mobileChange, {
          setMobileChange,
          setError: (errorMsg) => setError((prev) => ({ ...prev, usernameerror: errorMsg })),
          setNumberInvalid: setNumberinvalid,
          mobile_length,
          mobile_starting_digits,
        });
      }
  };
  const handlePaste1 = (e) => {
    const pastedText = e.clipboardData.getData('text');
    let numericPastedText = pastedText.replace(/[^0-9]/g, '');
    const dialCodeWithoutPlus = dialCode.replace('+', '');
    if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
        numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
    }
    validateAndSetMobileChange(numericPastedText, {
      setMobileChange: setMobileChange,
      setError: (errorMsg) => setError((prev) => ({ ...prev, usernameerror: errorMsg })),
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
    e.preventDefault();
}
    const customSingleValue = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
          />
          {/* {data.label} */}
        </div>
    );
    const customOption = (props) => {
        return (
          <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
              />
              {props.data.label}
            </div>
          </components.Option>
        );
    };
    const toggleLoginForm = () => {
      toggleLoginForms();
    }
    const check = useCallback(()=>{
      const sy = /^\+?\d+$/.test(mobileChange);
      const valid = (
        mobileChange !== '' && formValues.password !=='' &&  (sy ? numberinvalid === true : true)
      )
     setIslogged(valid);
    },[mobileChange,formValues.password,])
     useEffect(() => {
      check();
    },[check]);
    const login = async (event) => {
      event.preventDefault();
      const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let data;
      if (/^\+?\d+$/.test(mobileChange)) {
        if (!mccId) {
          setError((prev) => ({ ...prev, usernameerror: 'Select Your Country Again' }));
          return;
        }
        data = {
          username: mobileChange,
          password: formValues.password,
          mcc_id: mccId,
          device_type: 'web'
        };
      } else if (gmailUsernamePattern.test(mobileChange)) {
        data = {
          username: mobileChange,
          password: formValues.password,
          device_type: 'web'
        };
      } else {
        setError((prev) => ({ ...prev, usernameerror: 'Invalid Email Address' }));
        return;
      }
      try {
        setIsLoading4(true);
        const response = await axiosInstance.post(loginUrl, data);
    
        if (response.status === 200) {
          const X_AuthUser = response.data.data.authuser;
          const params = process_params();
          if (params.redirecturl) {
            const regex = /\/u\/([^/]+)/; 
            const matchall = params.allparams;
            const match1 = matchall.replace("redirecturl=", '');
            const url = match1.replace("&",'?');
            const match = url.match(regex); 
            if(match){
              const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
              window.location.href = updatedPathname;
            }
            else{
              const urlObj = new URL(url);
              urlObj.searchParams.set('x_authuser', X_AuthUser);
              window.location.href = urlObj.toString();
            }
          } else {
            window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
          }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            const X_AuthUser = error.response.data.data.authuser;
            const params = process_params();
            if (params.redirecturl) {
              const regex = /\/u\/([^/]+)/; 
              const matchall = params.allparams;
              const match1 = matchall.replace("redirecturl=", '');
              const url = match1.replace("&",'?');
              const match = url.match(regex); 
              if(match){
                const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
                window.location.href = updatedPathname;
              }
              else{
                const urlObj = new URL(url);
                urlObj.searchParams.set('x_authuser', X_AuthUser);
                window.location.href = urlObj.toString();
              }
            } else {
              window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
            }
          } else if (error.response.status === 401) {
            const error_code = error.response.data.code;
            if (error_code === 'auth_failed') {
              setIsLoading4(false);
              setError422(error.response.data.mobile_error);
            }
          } else {
            handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
          }
        } else {
          // Handle network errors or any other unexpected errors
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
      } finally {
        setIsLoading4(false);
      }
    };
    const [selwidth, setSelWidth] = useState(selectWidth(dialCode));
    const menuHeight = useMenuHeight(200, 400);
    useEffect(() => {
      setSelWidth(selectWidth(dialCode));
    }, [dialCode]);
  return (
    <>
    {!showCountryInput ? (
      <div className='logininputboxcenter'>
        <input type='email' autoComplete='off' name='email' className={`form-control logininputsign mt-4 ${mobileChange ? 'logininputsign-active' : ''}`} value={mobileChange} ref={inputRef} onChange={handleUsernameChange} onKeyPress={handleKeyPress} placeholder='Email or Mobile'/>
        <div style={{ position: 'absolute', right: '30px', top: '68%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
          <p onClick={toggleLoginForm} style={{ fontSize: '10px',marginBottom:'0px', fontWeight: "500", color:'#02CD8D'}}>Login With OTP</p>
          </div>
      </div>  
    ) : (
     <>
    <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center' ,width:'100%',maxWidth:'446px'}}>
    <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
         <Select options={countryOptions} onChange={handleCountryChange} value={countryOptions.find(option => option.value === countryCode)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={isLoading} styles={countrystyle('82px',menuHeight)}/>
         </div>
         <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
         <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
         <input type="text"  className={`form-control logininputsign ${mobileChange ? 'logininputsign-active' : ''}`}  ref={mobileInputRef} style={{ paddingLeft: `${selwidth + 18}px`, width: '100%' }} placeholder="Mobile Number" value={mobileChange}  onPaste={handlePaste1}  onChange={handleUsernameChange}/>
    </div>
     </> 
    )}
     {error.usernameerror && (<div className='errors'>{error.usernameerror}</div>)}
     <div className = "mt-3 logininputboxcenter" >
          <input type={formValues.showPassword ? 'text' : 'password'} name='password' autoComplete='new-password' className={`form-control logininputsign ${formValues.password ? 'logininputsign-active' : ''}`}   placeholder="Password"  value={formValues.password}onChange={(e) => {setError422('');setFormValues(prev => ({ ...prev, password: e.target.value }))}} onKeyPress={handleKeyPress} />
          <p className="toggle-password" onClick={togglePasswordVisibility} style={{ position: 'absolute', marginRight: '30px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
            {formValues.showPassword ? (
                formValues.password ? (
                <img src={eye} className='eyeslash' alt="Hide Password" />
                ) : (
                <img src={eyegrey} className='eyeslash' alt="Hide Password" />   
                )
            ) : (
                formValues.password ? (
                <img src={eyeslash} className='eyeslash' alt="Show Password" />
                ) : (
                 <img src={eyeslashgrey} className='eyeslash' alt="Show Password" />
                )
            )}
            </p>
            </div>
            <div className="w-100 d-flex justify-content-end" style={{maxWidth:'446px'}}>
            <Link to='/auth/forgot'><p style={{ fontSize: "12px", fontWeight: "600", lineHeight: "18px", marginTop: '10px', color: '#3E3E3E', cursor: 'pointer'}}>Forgot Password?</p></Link>
            </div>
            {error422 && (<div className='errors'>{error422}</div>)}
            <button className="loginsubmit" style={{backgroundColor:islogged ? '#F77F00' : '#EAEAEA',color:islogged ? 'white' : 'hsl(0, 0%, 20%)',cursor:islogged ? 'pointer' : 'not-allowed'}} disabled={!islogged} onClick={login}>Sign in</button>
           
    </>
  )
})

export default WithToast(Loginwithpassword);