import React,{useRef, useState,useEffect, useCallback} from 'react';
import { useCountryData1 } from '../api/hooks/mobilecountryhook';
import PhoneInputWithCountrySelector from '../components/phoneinputcountryselector';
import pen from '../images/pen (1).svg';
import { handleApiError, handleBeforeInput, handlePaste, successresponseerror, validateAndSetMobileChange, validateEmail } from '../api/utils/utils';
import Button from '../components/savebutton';
import { loginWithSendOtp, loginWithVerifyOtp } from '../api/helper/apiurlhelper';
function Loginwithotpnew({showErrorToast,toggleLoginForms,setIsLoading4}) {
  const [selectedCountry, setSelectedCountry] = useState({country_code: "",dial_code: "",mcc_id: "",mobile_length: "",mobile_starting_digits: "",mobileChange:"",numberinvalid:false,otp:'',}); 
  const [showscreen,setShowscreen] = useState({showmobileinput:false,showotp:false,islogged:false,islogged1:false}); 
  const [inlineerror,setInlineError] = useState({mobileerror:'',passworderror:''});
  const {countryOptions1,isLoading1,loadip} = useCountryData1();
  const mobileInputRef = useRef(null);
  const [error422,setError422] = useState('');
  const inputRef = useRef(null);
  useEffect(() => {
      if (showscreen.showmobileinput && mobileInputRef.current) {
          mobileInputRef.current.focus();
      } else if (inputRef.current) {
          inputRef.current.focus();
      }
   }, [showscreen.showmobileinput]);
   const handleInputChange = (e) => {
      const { name, value,} = e.target;
      setSelectedCountry((prevValues) => ({
          ...prevValues,
          [name]: value.trim(),
       }));
       if(name === 'mobileChange'){
          const value = e.target.value;
          if(/^\+?\d+$/.test(value)){
           setShowscreen((prev)=>({...prev,showmobileinput:true}));
           loadip(true, (data) => {
              if(data){
              setSelectedCountry((prev)=>({
                ...prev,
                country_code: data?.country_code,
                dial_code: `+${data?.dial_code}`,
                mcc_id: data?.mcc_id,
                mobile_length: data?.mobile_length,
                mobile_starting_digits: data?.mobile_starting_digits,
              }));
            }
            });
            validateAndSetMobileChange(value, {
              setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
              setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
              setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
              mobile_length: selectedCountry.mobile_length,             
              mobile_starting_digits: selectedCountry.mobile_starting_digits 
              });
          }else{
             setShowscreen((prev)=>({...prev,showmobileinput:false}));
             setSelectedCountry((prev)=>({...prev,mobileChange:value}));
             setInlineError((prev)=>({...prev,mobileerror:''}));
          } 
          setError422('');
       }
       setError422('');
   } 
   const handlePaste1 = (e) => {
    const pastedText = e.clipboardData.getData('text');
    let numericPastedText = pastedText.replace(/[^0-9]/g, '');
    const dialCodeWithoutPlus = selectedCountry.dial_code.replace('+', '');
    if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
        numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
    }
    validateAndSetMobileChange(numericPastedText, {
    setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
    setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
    setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
    mobile_length: selectedCountry.mobile_length,             
    mobile_starting_digits: selectedCountry.mobile_starting_digits 
    });
    e.preventDefault();
    };
    const handleCountryChange = (selectedOption) => {
        const selectedCountryData = countryOptions1.find(
            (country) => country.value === selectedOption.value
        );
        if (selectedCountryData) {
        const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
        setSelectedCountry((prev)=>({
            ...prev,
            country_code:country_code,
            dial_code: dial_code,
            mcc_id: mcc_id,
            mobile_length: mobile_length,
            mobile_starting_digits: mobile_starting_digits,
        }));
    
        validateAndSetMobileChange(selectedCountry.mobileChange, {
            setMobileChange:(val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
            setError: (errorMsg) => setInlineError((prev) => ({ ...prev, mobileerror: errorMsg })),
            setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
            mobile_length : mobile_length,
            mobile_starting_digits : mobile_starting_digits,
        });
        }
    }; 
    const check = useCallback(()=>{
          const sy = /^\+?\d+$/.test(selectedCountry.mobileChange);
          const valid = (
           selectedCountry.mobileChange !== '' &&  (sy ? selectedCountry.numberinvalid === true : true)
          )
         setShowscreen((prev)=>({...prev,islogged:valid}));
        },[selectedCountry.mobileChange])
         useEffect(() => {
          check();
        },[check]);
        const check1 = useCallback(()=>{
          const valid = (
            selectedCountry.otp !== ''
          )
          setShowscreen((prev)=>({...prev,islogged1:valid}));
        },[selectedCountry.otp])
         useEffect(() => {
          check1();
        },[check1]);
    const sendotp = async(event) => {
    event.preventDefault();
    let data;
    const isEmailValid = validateEmail(selectedCountry.mobileChange);
    if (/^\+?\d+$/.test(selectedCountry.mobileChange)){
    data = {
    username: selectedCountry.mobileChange,
    mcc_id: selectedCountry.mcc_id,
    };
    }else if(isEmailValid){
    data = {
    username: selectedCountry.mobileChange,
    };
    }else{
    setInlineError((prev) => ({ ...prev, mobileerror: 'Invalid Email Address' }));
    return;
    }
    try{
      setIsLoading4(true);  
      const response = await loginWithSendOtp(data);
      if(response.status === 200){
         setError422('');
         setSelectedCountry((prev)=>({...prev,otp:''}));
         setShowscreen((prev)=>({...prev,showotp:true}));
      }
    }catch(error){
    handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }finally{
    setIsLoading4(false);
    }
    }
    const confirm = async(event) => {
      event.preventDefault();
      const data = {
        otp : selectedCountry.otp,
        device_type :'web'
      }
      try{
      const response = await loginWithVerifyOtp(data);
      if(response.status === 200){
          await successresponseerror(response,'response');
      }
      }catch(error){
       handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
      }finally{
        setIsLoading4(false);
      }
    }
  return (
   <>
   <form autoComplete='off' className='w-100'>
   <div className='logininputboxcenter mt-4'>
   {!showscreen.showotp ? (
   !showscreen.showmobileinput ? (
    <div className={`newinput ${selectedCountry.mobileChange ? 'newinput_active' : ''}`}>
    <input type='email' name='mobileChange' placeholder='Email or Mobile' value={selectedCountry.mobileChange} onChange={handleInputChange}  className={`form-control logininputsigninside input_passwordwidth ${selectedCountry.mobileChange ? 'logininputsigninside-active' : ''}`} ref={inputRef}></input>
    <div className='seperatediv seperatediv2' ><p className='ptag cursor-pointer' onClick={toggleLoginForms}>Login With Password</p></div>
    </div>
    ) : (
     <div className={`newinput position-relative ${selectedCountry.mobileChange ? 'newinput_active' : ''}`}>   
     <PhoneInputWithCountrySelector countryOptions={countryOptions1} name='mobileChange'  handleCountryChange={handleCountryChange} dialCode={selectedCountry.dial_code} mobileChange={selectedCountry.mobileChange} handlePhoneNumberChange={handleInputChange}  handlePaste={handlePaste1} countryCode={selectedCountry.country_code} walletprofileinput = 'logininputsigninside input_passwordwidth' walletprofileinputactive='logininputsigninside-active' isLoading={isLoading1} placeholder="Mobile Number" ref={mobileInputRef} numeric={true}/> 
     <div className='seperatediv seperatediv2' ><p className='ptag cursor-pointer' onClick={toggleLoginForms}>Login With Password</p></div>
     </div>   
    )
    ) : (
     <>   
    <div className='d-flex justify-content-between align-items-center rounded mt-3 verify_input_div'>
    <div className='d-flex align-items-center gap-2 width90'>
        <p className='mb-0 text-truncate'>{/^\+?\d+$/.test(selectedCountry.mobileChange) ? `${selectedCountry.dial_code} ${selectedCountry.mobileChange}` : `${selectedCountry.mobileChange}` }</p>
    </div>
    <div className='width10 text-center'> 
    <img src={pen} alt='pen' onClick={()=>setShowscreen((prev)=>({...prev,showotp:false}))}></img>
    </div>
    </div>
    <input type='text' name='otp' placeholder='Enter OTP' value={selectedCountry.otp} onChange={handleInputChange} onBeforeInput={handleBeforeInput} onPaste={(e) => handlePaste(e, (val) => {setSelectedCountry((prev) => ({ ...prev, otp: val }));setError422('')})} maxLength={6} className={`form-control logininputsign ${selectedCountry.otp ? 'logininputsign-active' : ''}`} ></input>  
    </> 
    )}
    {inlineerror.mobileerror &&  <div className="errors">{inlineerror.mobileerror}</div>}
    {error422 && (<div className='errors'>{error422}</div>)}
    <Button defaultclassname='savedefault mt-4' onClick={!showscreen.showotp ? sendotp : confirm} enabled={!showscreen.showotp ? showscreen.islogged : showscreen.islogged1} activeclassname='savedefault_active' disabledclassname='savedefault_disabled' label='Send OTP'/>
   </div>
   </form>
   </>
  )
}

export default Loginwithotpnew