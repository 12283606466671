import React from 'react';
import plus from '../images/plus (1).svg';
const Button = ({ 
  enabled = true, 
  onClick=()=>{}, 
  label = 'Save', 
  defaultclassname = 'modal_save_card',
  activeclassname = 'modal_save_card_active',
  disabledclassname = 'modal_save_card_disabled',
  active=false,
  icon=false, 
}) => {
  const buttonClass = active ? defaultclassname :`${defaultclassname} ${enabled ? activeclassname : disabledclassname}`.trim();

  return (
    <button 
      className={buttonClass} 
      disabled={!enabled} 
      onClick={onClick}
    >{icon && <img src={plus} className="me-2 plus" alt="plus"></img>}
      {label}
    </button>
  );
};

export default Button;
