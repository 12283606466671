import { useState,useEffect,useCallback } from "react";
import { myaccountdomain, publicDomain,  } from "../helper/urlhelper";
const myaccountdomainurl = myaccountdomain();
const publicDomainurl = publicDomain();
export const Error401 = () => {
    const lod4 = extractLoginUser();
    window.location.href = `/refresh?X-Authuser=${encodeURIComponent(lod4)}`;
    return lod4;
}
export const extractLoginUser = () => {
    const routePath = window.location.pathname;
    const regex = /\/u\/([^/]+)/; // Match the last part after the last slash
  
    const match = routePath.match(regex);
    if (match) {
        return match[1]; // Return the matched value
    }
    return null; // Return null if no match is found
}  
export const successresponseerror = async(response,mode)=>{
   const X_AuthUser = mode === 'response' ? response.data.data.authuser : response.response.data.data.authuser;
            const params = process_params();
            if (params.redirecturl) {
              const regex = /\/u\/([^/]+)/; 
              const matchall = params.allparams;
              const match1 = matchall.replace("redirecturl=", '');
              const url = match1.replace("&",'?');
              const match = url.match(regex); 
              if(match){
                const updatedPathname = url.replace(new RegExp(`/u/${match[1]}/`), `/u/${X_AuthUser}/`);
                window.location.href = updatedPathname;
              }
              else{
                const urlObj = new URL(url);
                urlObj.searchParams.set('x_authuser', X_AuthUser);
                window.location.href = urlObj.toString();
              }
            } else {
              window.location.href = `${myaccountdomainurl}/u/${X_AuthUser}/services`;
            }
}
export const handleApiError = async(error, setIsLoading = null, setError422 = null, handle422AsToast = true, showErrorToast,errorcall=null) => {
    if (typeof showErrorToast !== 'function') {
      console.error('showErrorToast must be a function');
      return;
    }
    if (error.response) {
      const { status, data } = error.response;
      switch (status) {
        case 401:
          const errorCode = data.error_code || data.code;
          if (errorCode === 'token_expired' || errorCode === 'already_logged_out') {
            Error401();
          }else if(errorCode === 'auth_failed'){
            setError422(data.mobile_error);
          } 
          else {
            showErrorToast(data.mobile_error || 'Unauthorized access.', '401Error');
          }
          break;
        case 409:
          await successresponseerror(error,'error');
          break;
        case 422:
          if (handle422AsToast) {
            showErrorToast(data.mobile_error || 'Validation error.', '422Error');
          } else{
            setError422(data.mobile_error || 'Validation error.');
          }
          break;
  
        case 500:
          showErrorToast(data.mobile_error || 'Internal server error.', '500Error');
          break;
  
        default:
          showErrorToast(data.mobile_error || 'An unexpected error occurred.','UnexpectedError');
          break;
      }
    } else {
      showErrorToast('Network error or server is unreachable.', 'NetworkError');
    }
  
    if (typeof setIsLoading === 'function') {
      setIsLoading(false);
    }
};
export const redirectToWithAuthUser = (redirectUrl, X_AuthUser) => {
    const queryParams = {
      X_AuthUser: X_AuthUser
    };
    redirectTo(redirectUrl, queryParams);
}

export const mergeAllQueryParameters = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
  
    // Extract and store the value of the 'redirecturl' parameter
    const redirectUrl = urlParams.get('redirecturl');
  
    // Create an array of entries, excluding the 'redirecturl' parameter
    const filteredParams = Array.from(urlParams.entries())
      .filter(([key]) => key !== 'redirecturl') // Filter out the 'redirecturl' parameter
      .map(([key, value]) => `${key}=${value}`) // Format the parameters
      .join('&'); // Join them with '&'
      
    const result = redirectUrl ? `${redirectUrl}${filteredParams}` : filteredParams;
    return result;
};
  
function redirectTo(url, params) {
    const queryString = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    const redirectUrl = `${url}?${queryString}`;
    window.location.href = redirectUrl;
}

export const getMaskedEmail = (email) => {
    if (!email) {
      return null;
    }
    const [username, domain] = email.split('@');
    const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 1);  
    return `${maskedUsername}@${domain}`;
};

export const getMaskedPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return null;
    }
  
    const lastTwoDigits = phoneNumber.slice(-2);
  
    return `**********${lastTwoDigits}`;
};

export const get_url_params = () => {
  const current = window.location.href;
  const urlObject = new URL(current);
  const searchParams = new URLSearchParams(urlObject.search);
  const params = {};  // Initialize an empty object to store key-value pairs
  
  // Iterate over search parameters and add them to the params object
  searchParams.forEach((value, key) => {
    params[key] = value;  // Store the key-value pair in the object
  });

  return params;
}

export const process_params = () => {
  // Get the URL parameters from the get_url_params function
  const params = get_url_params();

  // Extract the 'redirecturl' param value and remove it from the params object
  const redirecturlValue = params.redirecturl ? `redirecturl=${params.redirecturl}` : '';
  const redirecturl = params.redirecturl ?  `${params.redirecturl}` : ''
  const is_auth_check = params.is_auth_check ?  `${params.is_auth_check}` : ''
  delete params.redirecturl;  // Remove 'redirecturl' key from params

  // Remove 'is_auth_check' key from params
  delete params.is_auth_check;

  // Extract the first parameter key and its value
  // const firstKey = Object.keys(params)[0];
  // const firstValue = params[firstKey] || '';

  // // Remove the first key from the params object
  // delete params[firstKey];

  // Combine remaining parameters into a query string
  const otherParamsString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

  // Construct the final 'allparams' string with 'redirecturl' first, then firstValue (without its key), followed by other parameters
  const allparams = [
    redirecturlValue,
    // firstValue,
    otherParamsString
  ].filter(part => part).join('&');

  return {
    redirecturl: redirecturl,
    is_auth_check:is_auth_check,
    allparams: allparams 
  };
}


export const extractLengthValues = (regexString) => {
  if (regexString === null || typeof regexString !== 'string') return null;
  const match = regexString.match(/\\d\{(\d+)(?:,(\d+))?\}/);
  if (match) {
    const minlength = parseInt(match[1], 10);
    const maxlength = match[2] ? parseInt(match[2], 10) : minlength;
    return { minlength, maxlength };
  }
  return null;
};
// export const calculateWidth = (label) => {
//   const tempDiv = document.createElement('p');
//   Object.assign(tempDiv.style, {
//     position: 'absolute',
//     visibility: 'hidden',
//     fontSize: '14px',
//     fontWeight: '400',
//     whiteSpace: 'nowrap',
//     margin: '0',
//   });
//   tempDiv.innerText = label;
//   document.body.appendChild(tempDiv);
//   const width = tempDiv.offsetWidth;
//   document.body.removeChild(tempDiv);
//   return width;
// };

// export const selectWidth = (dial = '') => calculateWidth(dial) + 82;

// export const calculateMenuHeight = (offset = 200, maxHeight = 400) => {
//   const availableHeight = window.innerHeight - offset;
//   return `${Math.min(availableHeight, maxHeight)}px`;
// };

export const countrystyle = (width,height,none,loadwidth) => ({
  control: (provided) => ({
    ...provided,
    width:width,
    height: '100%',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display:'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: '300px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    height: height,
    overflowY: 'auto',
  }),
  loadingIndicator:(provided) => ({
    ...provided,
    color:'black'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display:none
  })
});
export const countrystyle1 = (width,menuHeight,height,show) => ({
  control: (provided) => ({
    ...provided,
    width:width,
    height: '100%',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    minHeight:`${height}px`
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
    display:show ? '' : 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex:9999,
    minWidth: '300px',
    position:'absolute'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    height: menuHeight,
    zIndex:9999,
    overflowY: 'auto',
  }),
  loadingIndicator:(provided) => ({
    ...provided,
    color:'black'
  })
});
export const validateAndSetMobileChange = (
  value,
  { setMobileChange, setError, setNumberInvalid, mobile_length, mobile_starting_digits }
) => {

  const { maxlength = 15, minlength = 15 } = extractLengthValues(mobile_length) || {};
  const startingDigitPattern = mobile_starting_digits ? new RegExp(`^${mobile_starting_digits}`) : null;
  if (value.startsWith('0')) value = value.slice(1);
  if (value.length > maxlength) value = value.slice(0, maxlength);

  const isValid = value.length >= minlength && value.length <= maxlength &&
                  (!startingDigitPattern || startingDigitPattern.test(value));
  setError(isValid || value.length === 0 ? '' : 'Invalid number');

  setNumberInvalid(isValid);
  setMobileChange(value);
};
export const handlePastesubmit = (e, callback, setIsSubmitting, maxDigits = 6) => {
  const pastedText = e.clipboardData.getData('text/plain');
  const numericText = pastedText.replace(/\D/g, ''); 
  const limitedText = numericText.slice(0, maxDigits); 

  e.preventDefault();

  if (callback && typeof callback === 'function') {
    callback(limitedText);
  }

  // If pasted OTP is exactly 6 digits, set isSubmitting to true
  if (limitedText.length === maxDigits) {
    setIsSubmitting(true);
  }
};
// export const validateAndSetMobileChange = (
//   value,
//   { setMobileChange, setError, setNumberInvalid, mobile_length, mobile_starting_digits }
// ) => {
//   const { maxlength = 15, minlength = 15 } = extractLengthValues(mobile_length) || {};
//   const startingDigitPattern = mobile_starting_digits ? new RegExp(`^${mobile_starting_digits}`) : null;

//   // Remove leading zero
//   if (value.startsWith('0')) value = value.slice(1);

//   // Trim to effective max length
//   if (value.length > maxlength) value = value.slice(0, maxlength);

//   // Validate based on length and starting digits
//   const isValid = value.length >= minlength && value.length <= maxlength &&
//                   (!startingDigitPattern || startingDigitPattern.test(value));

//   setError(isValid || value.length === 0 ? '' : 'Invalid number');
//   setNumberInvalid(isValid);
//   setMobileChange(value);
// };
export const customStyles1 = (hasValue) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#DCDCDC',
    height:'46px',
    fontSize:'14px',
    borderRadius:'.25rem',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#DCDCDC',
    },
  }),container: (provided) => ({
    ...provided,
    width:'100%',
    maxWidth:'446px',
    borderRadius:'.25rem',
    marginTop:'.5rem', 
  }),placeholder: (provided) => ({
    ...provided,
    // color: '#ABABAB',
    width: '100%',
    fontSize:'12px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#DCDCDC',
    svg: {
      fill: hasValue ? 'black' : '#B9B9B9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
export const handleBeforeInput = (e) => {
  if (!/^\d$/.test(e.data)) {
    e.preventDefault();
  }
};
export const handleInputNameValidation = (e,{setError}) => {
  if (!/^[a-zA-Z\s]*$/.test(e.data)) {
    e.preventDefault();
    setError('Only alphabets are allowed');
  } else {
    setError('');
  }
};
export const calculateWidth = (label) => {
  const tempDiv = document.createElement('p');
  Object.assign(tempDiv.style, {
    position: 'absolute',
    visibility: 'hidden',
    fontSize: '14px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    margin: '0',
  });
  tempDiv.innerText = label;
  document.body.appendChild(tempDiv);
  const width = tempDiv.offsetWidth;
  document.body.removeChild(tempDiv);
  return width;
};

export const selectWidth = (dial = '') => calculateWidth(dial) + 82;

export const calculateMenuHeight = (offset = 200, maxHeight = 400) => {
  const availableHeight = window.innerHeight - offset;
  return `${Math.min(availableHeight, maxHeight)}px`;
};
const useMenuHeight = (offset = 200, maxHeight = 400) => {
  const [menuHeight, setMenuHeight] = useState(calculateMenuHeight(offset, maxHeight));

  const updateMenuHeight = useCallback(() => {
    setMenuHeight(calculateMenuHeight(offset, maxHeight));
  }, [offset, maxHeight]);

  useEffect(() => {
    updateMenuHeight(); // Initial height calculation
    window.addEventListener('resize', updateMenuHeight); // Listen for resize events

    return () => {
      window.removeEventListener('resize', updateMenuHeight); // Cleanup
    };
  }, [updateMenuHeight]);

  return menuHeight;
};
export default useMenuHeight;
export const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};
export const handlePaste = (e, callback, maxDigits = 6) => {
  const pastedText = e.clipboardData.getData('text/plain');
  const numericText = pastedText.replace(/\D/g, '');
  const limitedText = numericText.slice(0, maxDigits);
  if (callback && typeof callback === 'function') {
    callback(limitedText);
  }
  e.preventDefault();
};
export const getImageSrc = (image) => {
  const removeDuplicateSubstrings = (url) => {
    const regex = /(https?:\/\/[^\/]+)(\/\1)+/g;
    return url.replace(regex, '$1');
  };
  const removeCore = (url) => {
    return url.replace(/(\/?core\/)+/g, '/core/');
   };
  if (image instanceof File || image instanceof Blob) {
    return {success:true,file:URL.createObjectURL(image)}; 
  } else if (typeof image === "string" && image.trim() !== "") {
    let fullUrl = `${publicDomainurl}/${image}`;
    fullUrl = removeDuplicateSubstrings(fullUrl);
    fullUrl = removeCore(fullUrl)
    return { success: false, file: fullUrl };
  }
  return { success: false, file: "" }; 
};