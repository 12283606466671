import React, { createContext, useCallback, useContext, useState,useEffect } from 'react';
import { mcc_list_ip } from "../helper/apiurlhelper";
import { useIpContext } from "../hooks/iphook";
import { handleApiError } from "../utils/utils";
import WithToast from './withtoasthook';

const CountryDataContext1 = createContext();

export const useCountryData1 = () => {
  return useContext(CountryDataContext1);
};
export const CountryDatasProvider = WithToast(({ children, showErrorToast }) => {
      const { ipCountryCode, getUserIpCountryCode } = useIpContext();  
      const [shold, setShold] = useState(false);
      const [newcountry,setNewcountry] = useState('');
      const [shold1, setShold1] = useState(false);
      const [isLoading1,setIsLoading] = useState(false);
      const [countryOptions1, setCountryOptions] = useState([]);
      const [callbackFn, setCallbackFn] = useState(null);
  const fetchCountryData = useCallback(async(code) => {

    if(!code) return;
    try {
      setIsLoading(true);
      const res = await mcc_list_ip();
      const mcc_list = res.data.data;

      const codes = mcc_list.map((mcc) => ({
        country_code: mcc.country_code,
        dial_code: mcc.code,
        mcc_id: mcc.id,
        label: `${mcc.name} (+${mcc.code})`,
        value: mcc.country_code,
        flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
        mobile_length:mcc.mobile_length,
        mobile_starting_digits:mcc.mobile_starting_digits
      }));
      
      setCountryOptions(codes); 
      const value = code
      const selectedCountryData = codes.find((country) => country.country_code === value);
      if (selectedCountryData && callbackFn) {
        callbackFn(selectedCountryData);
        setCallbackFn(null); 
        setNewcountry('');
      }
    } catch (error) {
      handleApiError(error, null, null, true, showErrorToast);
    }finally{
      setIsLoading(false);
      setShold(false);
    }
  },[callbackFn]);
  useEffect(()=>{
  if(shold && ipCountryCode && countryOptions1.length > 0){
    const selectedCountry = countryOptions1.find((country) => country.country_code === ipCountryCode);
    if (selectedCountry && callbackFn) {
        callbackFn(selectedCountry);
        setCallbackFn(null); 
        setShold(false);
        setIsLoading(false);
    }
  }
},[shold,ipCountryCode,countryOptions1,callbackFn])
  useEffect(()=>{
   if(ipCountryCode && shold && countryOptions1.length === 0){
    fetchCountryData(ipCountryCode);
   }
  },[ipCountryCode,shold,countryOptions1]);
  useEffect(()=>{
    if(!ipCountryCode && shold){
        getUserIpCountryCode();
    }
  },[ipCountryCode,shold,getUserIpCountryCode]);
  const loadip = (shold,callback = null) => {
    if(shold){
        setShold(true);
        setCallbackFn(()=>callback);
        setIsLoading(true);
    }
  }
  useEffect(()=>{
    if(shold1 && callbackFn && newcountry && countryOptions1.length === 0){
        fetchCountryData(newcountry).finally(()=>setIsLoading(false),setShold1(false));
    }
  },[shold1,newcountry,countryOptions1,callbackFn])
  const loadselection = (code,add,callback =null) => {
        if(countryOptions1.length > 0 && code ){
        const selectedCountry = countryOptions1.find((country) => country.country_code === code);
        if(selectedCountry && callback){
           callback(selectedCountry)    
        }
     }else{
      if(add && code){
          setShold1(true);
          setNewcountry(code);
          setCallbackFn(()=>callback);
      }
     }
  }

  const value = {
     loadip,countryOptions1,isLoading1,loadselection
  }
  return (
    <CountryDataContext1.Provider value={value}>
      {children}
    </CountryDataContext1.Provider>
  );
});