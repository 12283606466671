import { useDispatch, useSelector } from 'react-redux';
import { fetchCountrys } from '../../redux/actions/countryaction';
export const useCountryOptions = ({ showErrorToast}) => {
    const dispatch = useDispatch();
    const { countrylistoptions, loading, error } = useSelector((state) => state.country);
  
    const loadcountry = () => {
      dispatch(fetchCountrys(showErrorToast));
    };
  
    return { countrylistoptions, loading, error, loadcountry };
};