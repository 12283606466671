function domain()
{
    return process.env.REACT_APP_API_URL;
}
function coredomain()
{
    return domain() + '/api/v1.0/app/core';
}
export function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL + '/core';
}
export function myaccountdomain()
{
    return process.env.REACT_APP_MY_ACCOUNT_URL;
}
export function privacypolicydomain()
{
    return process.env.REACT_APP_PRIVACY_API_URL;
}
// export function publicDomainimage(){
//     return publicDomain() + '/core'
// }
export function sidapi()
{
    return coredomain() + "/auth/sid";
}
export function ipapi()
{
    return coredomain() + "/general/ip";
}
export function loginapi()
{
   return coredomain() + "/auth/login";
}
export function country_codeapi()
{
    return coredomain() + "/general/mcc/list/all";
}
export function countryselectapi()
{
    return coredomain() + "/general/country/list/all";
}
export function loginwithotpapi()
{
    return coredomain() + '/auth/login/otp';
}
export function loginwithotpverifyapi()
{
    return coredomain() + '/auth/login/otp/verify';
}
export function signupapi()
{
    return coredomain() + "/auth/signup";
}
export function signupverifyapi()
{
    return coredomain() + '/auth/verify-otp';
}
export function signupupdateapi()
{
    return coredomain() + '/auth/signup/update';
}
export function forgotpasswordsendotpapi()
{
    return coredomain() + '/auth/forgot/password';
}
export function forgotpasswordotpverifyapi()
{
    return coredomain() + '/auth/forgot/password/verify-otp';
}
export function forgotresetpasswordapi()
{
    return coredomain() + '/auth/reset/password';
}
export function logoutremoveapi()
{
    return coredomain() + '/auth/logout/remove';
}
export function logoutapi()
{
    return coredomain() + '/auth/logout';
}
export function logoutallapi()
{
    return coredomain() + '/auth/logout/all';
}
export function refreshapi()
{
    return coredomain() + '/auth/refresh/token';
}
export function signupotpsendapi()
{
    return coredomain() + '/auth/signup/otp/send';
}
export function signupotpverifyapi()
{
    return coredomain() + '/auth/signup/otp/verify';
}
export function signupproceedapi()
{
    return coredomain() + '/auth/signup/proceed';
}