import React, { useCallback, useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import resetpasswordlock from '../../images/Group 1416.svg';
import { getMaskedEmail, getMaskedPhoneNumber, handleApiError, handleBeforeInput, handlePastesubmit } from '../../api/utils/utils';
import OTPInput from 'react-otp-input';
import { privacypolicydomain } from '../../api/helper/urlhelper';
import { forgotVerifyOtp } from '../../api/helper/apiurlhelper';
function Forgototppagenew({formValues,setIsLoading4,setShowscreen,showscreen,sendcode,showErrorToast}) {
   const navigate = useNavigate(); 
   const [otpState,setOtpState] = useState({otp:'',countdown:120,issubmit:false});
   const [error422,setError422] = useState('');
   const handleChange = (otp) => {
    setError422('');
    setOtpState(prevState => ({ ...prevState, otp }));
    if (otp.length === 6 && !otpState.issubmit) {
        setOtpState(prevState => ({ ...prevState, issubmit:true }));
    }
   };
   const startCountdown = async() => {
           setOtpState((prevState) => ({ ...prevState, countdown: 60 })); // Reset countdown
           let timer = setInterval(() => {
             setOtpState((prevState) => {
               if (prevState.countdown <= 1) {
                 clearInterval(timer);
                 return { ...prevState, countdown: 0 };
               }
               return { ...prevState, countdown: prevState.countdown - 1 };
             });
           }, 1000);
         
           return timer;
         };
         useEffect(() => {
           let timer;
           if (showscreen.forgotverify) {
             timer = startCountdown(); 
           }         
           return () => clearInterval(timer);
         }, [showscreen.forgotverify]);
   const otpverify = useCallback(async()=>{
     const data = {
        otp:otpState.otp
     }
     try{
       setIsLoading4(true);
       const response = await forgotVerifyOtp(data);
       if(response.status === 200){
         setShowscreen((prev)=>({...prev,
            forgotverify:false,forgotpass:true
         }));
       }
     }catch(error){
       handleApiError(error,setIsLoading4,setError422,false,showErrorToast) 
     }finally{
       setIsLoading4(false)
     }
   },[otpState.otp]);
   const resendotp = async()=>{
     await sendcode();
     await startCountdown();
   }
   useEffect(()=>{
    if(otpState.otp.length === 6 && otpState.issubmit){
        otpverify();
    }
    },[otpState.otp,otpverify,otpState.issubmit]);
   const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const url = privacypolicydomain();
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <>
       
       <div className='resetpasspad'>
       <div className='d-flex flex-column justify-content-center align-items-center pt-5'>
       <img src={resetpasswordlock} alt='passwordlock' className='resetppasslock' width={80} height={80}></img>
       <h6 className='mt-2 resetpassh6'>Enter OTP Code</h6>
       <p className='resetpassp resetpassp1'>Enter the 6-digit code sent<br />
                       to <strong>{formValues.forgotvalueselect === 'Email' ? `${getMaskedEmail(formValues.email)}` : `${formValues.dial_code}${getMaskedPhoneNumber(formValues.mobileChange)}`}</strong><br /> Did not receive OTP?
                       <strong className={`${otpState.countdown > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: otpState.countdown > 0 ? 'not-allowed' : 'pointer' }} onClick={otpState.countdown <= 0 ? resendotp : null}> Resend OTP </strong>
                     </p>
                     <OTPInput
                value={otpState.otp}
                onChange={handleChange}
                numInputs={6}
                renderInput={(props, index) => (
                  <input {...props}  onPaste={(e) =>
                    handlePastesubmit(e, (val) => {
                      setOtpState((prev) => ({ ...prev, otp: val }));
                      setError422('');
                    },(val)=>setOtpState((prev)=>({...prev,issubmit:val}))) 
                  } id={`otp-input-${index}`} name={`otp-input-${index}`} className={`custom-inputotp mt-3 mt-sm-4`} style={{ border: otpState.otp[index] ? '1px solid black' : '' }} onBeforeInput={handleBeforeInput} />
                )}
              />
              {error422 && <div className="errors mt-1">{error422}</div>}
              <div className='mt-3  d-flex justify-content-center w-100'>
                <button type="button" className='resendotp'>
                  {otpState.countdown > 0 ? `${otpState.countdown}S` : `${otpState.countdown}S`}
                </button>
              </div>
              <p className='mt-4 mb-4 custom_trouble'>Having any trouble?<strong className='custom_trouble_help cursor-pointer' onClick={handleClick}> Get Help</strong></p>
       </div>
       </div>
    </>
  )
}

export default Forgototppagenew